import React, { useState, useEffect } from "react";
import { v4 as genId } from "uuid";
import { Chatbox } from "./styles";
import ChatRequisition from "../ChatRequisition";

const Modal = (props: any) => {
  const { order, updateList } = props;
  const [groupMembersChat, setGroupMembersChat] = useState<any>([]);

  const getMembers = () => {
    const userFrom = {
      // userFromId: userData?.userId,
      // userFromName: userData?.userName,
    };

    const approvers = order?.approvers?.map((approver: any) => ({
      key: genId(),
      ...userFrom,
      userToId: approver.userId,
      userToName: approver.userName,
    }));

    const requester = {
      key: genId(),
      ...userFrom,
      userToId: order?.creationUserId,
      userToName: order?.creationUserName,
    };

    const buyer = {
      key: genId(),
      ...userFrom,
      userToId: order?.buyerId,
      userToName: order?.buyerName,
    };

    if (approvers) {
      setGroupMembersChat([
        {
          title: "Requisitante",
          members: [requester],
        },
        // {
        //   title: "Comprador",
        //   members: [buyer],
        // },
        {
          title: "Alçada de aprovação",
          members: approvers,
        },
      ]);
    } else {
      setGroupMembersChat([
        {
          title: "Requisitante",
          members: [requester],
        },
        // {
        //   title: "Comprador",
        //   members: [buyer],
        // },
      ]);
    }
  };

  useEffect(() => {
    getMembers();
  }, [order]);

  return (
    <Chatbox>
      <ChatRequisition
        groupMembers={groupMembersChat}
        purchaseOrderId={order?.id}
        setGroupMembers={setGroupMembersChat}
        order={order}
        updateList={updateList}
      />
    </Chatbox>
  );
};

export default Modal;
