import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Tag,
  Steps,
  Form,
  Divider,
  Radio,
  Rate,
  message,
  Upload,
  Checkbox,
} from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router";
import {
  PageHeader,
  Section,
  TextField,
  DateField,
  SelectField,
  CheckField,
  AreaField,
} from "components";
import { TProjectForm, useRequest } from "repositories";
import { usePagination, useAuth } from "hooks";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import moment from "moment";
// import { initialValues } from "pages/Auth/Login";

const { Dragger } = Upload;

const props = {
  name: "file",
  multiple: true,
  action: "",
  onChange(info: any) {
    const { status } = info.file;
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e: any) {
    // console.log("Dropped files", e.dataTransfer.files);
  },
};

const steps = [
  {
    title: "Projeto",
    content: "First-content",
    description: "Selecione o Projeto",
  },
  {
    title: "Itens",
    content: "Second-content",
    description: "Escolha as linhas / produtos",
  },
  {
    title: "Complementos",
    content: "Last-content",
    description: "Preencha o formulário",
  },
  {
    title: "Freelancers",
    content: "Last-content",
    description: "Preencha o formulário Funcional",
  },
];

const columnsSuppliers: any = [
  {
    title: "Id",
    dataIndex: "Id",
    align: "center",
  },
  {
    title: "Razão Social",
    dataIndex: "Social",
    align: "center",
  },
  {
    title: "Nome fantasia ",
    dataIndex: "fantasia",
    align: "center",
  },
  {
    title: "Tipo de Prestador",
    dataIndex: "Prestador",
    align: "center",
  },
  {
    title: "Telefone",
    dataIndex: "Telefone",
    align: "center",
  },
  {
    title: "E-mail",
    dataIndex: "mail",
    align: "center",
  },
  {
    title: "Status Homologação",
    dataIndex: "Status",
    align: "center",
    render: (_: any, record: any) => <Tag color="green">Homologado</Tag>,
  },
  {
    title: "Avaliações",
    dataIndex: "Avaliacoes",
    align: "center",
    render: (_: any, record: any) => (
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <Rate disabled value={3} />
        <span>(18)</span>
      </div>
    ),
  },
  {
    title: <Button type="primary">Adicionar</Button>,
    dataIndex: "ações",
    align: "center",
    render: (_: any, record: any) => <Button type="link">Excluir</Button>,
  },
];

const columnsLines = [
  {
    title: "Id Compras",
    dataIndex: "id",
  },
  {
    title: "Id da linha TOTVS",
    dataIndex: "idLinha",
  },
  {
    title: "Tipo do Faturamento",
    dataIndex: "paymentType",
  },
  {
    title: "Produto",
    dataIndex: "productDescription",
  },
  {
    // oq é ?
    title: "Categoria",
    dataIndex: "categoria",
  },
  {
    title: "Escopo da linha",
    dataIndex: "technicalScope",
  },
  {
    title: "Quantidade",
    dataIndex: "amount",
  },
  {
    title: "Diárias",
    dataIndex: "daily",
  },
  {
    title: "Custo Unítario",
    dataIndex: "unitCost",
  },
  {
    title: "Custo total",
    dataIndex: "totalCost",
  },
  {
    title: "Já tem requisições?",
    dataIndex: "withoutRequest",
  },
  {
    title: "Alvo de pacote ou rateio?",
    dataIndex: "packageOrApportionment",
  },
  {
    // soma da quantidade + valor
    title: "Requisições aprovadas R$",
    dataIndex: "approvalRequests",
  },
  {
    title: "Saldo da linha",
    dataIndex: "lineBalance",
  },
];

const options = [
  { label: "Monday", value: "Monday" },
  { label: "Compras", value: "Compras" },
  { label: "Elaw", value: "Elaw" },
  { label: "Cervello", value: "Cervello" },
  { label: "Power BI", value: "Power BI" },
  { label: "TOTVS", value: "TOTVS" },
  { label: "Pacote Office", value: "Pacote Office" },
];

const FormFreelancers = ({
  request,
  prevStep,
  createRequest,
  freelancersData,
  currentFreelancerData,
  loading,
}: any) => {
  const [form] = Form.useForm(); //<TProjectForm>
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [access, setAccess] = useState([]);

  const [btnClick, setBtnClick] = useState("");

  const { roles: authRoles, hasRole } = useAuth();
  const repositoryRequest = useRequest();
  const navigate = useNavigate();
  const pagination = usePagination();

  const requestCreate = async () => {
    form.setFieldValue(
      "needSupportFromTv1corporateTI",
      currentFreelancerData.needSupportFromTv1corporateTI ? "sim" : "nao"
    );
    form.setFieldValue(
      "useTv1Email",
      currentFreelancerData.useTv1Email ? "sim" : "nao"
    );
    form.setFieldValue(
      "equipmentFreelancers",
      currentFreelancerData.equipmentFreelancers
    );
    form.setFieldValue(
      "equipmentTypeFreelancers",
      currentFreelancerData.equipmentTypeFreelancers
    );
    form.setFieldValue("workplace", currentFreelancerData.workplace);

    if (currentFreelancerData.accessInternalSystem?.length > 0) {
      setAccess(currentFreelancerData.accessInternalSystem);
    }
    form.setFieldValue(
      "othersAccessInternalSystem",
      currentFreelancerData.othersAccessInternalSystem
    );
    form.setFieldValue(
      "otherMarketSoftware",
      currentFreelancerData.otherMarketSoftware
    );
    form.setFieldValue(
      "freelanceDirectManager",
      currentFreelancerData.freelanceDirectManager
    );
  };

  const onChange = (checkedValues: any) => {
    setAccess(checkedValues);
    console.log();
  };

  let initialValues = {
    contractStartDate: moment(request?.serviceStartDate),
    contractEndDate: moment(request?.serviceEndDate),
  };

  useEffect(() => {
    if (pagination.data.current) requestCreate();
  }, [pagination.data.current, pagination.data.pageSize, sort, search]);

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado" />;
  }

  const onFinish = async (values: any) => {
    values.accessInternalSystem = access;
    switch (btnClick) {
      case "back":
        values.btnStatus = "back";
        freelancersData();
        prevStep();
        break;
      case "save":
        values.btnStatus = "save";
        // console.log(values, "save");
        freelancersData(values);
        break;
    }
  };

  const next = () => {
    // onFinish()
  };

  const prev = () => {};

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    description: item.description,
  }));

  return (
    <>
      <Section>
        {/* <Steps current={3} items={items} /> */}
        <div style={{ marginTop: 24 }}>
          <Form
            form={form}
            initialValues={initialValues}
            layout="vertical"
            onFinish={onFinish}
          >
            <>
              <Row>
                <Col>
                  <label>
                    Formulário funcional complementar para Freelancers
                  </label>
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col xl={6} xxl={4}>
                  <SelectField
                    name="needSupportFromTv1corporateTI"
                    label="Requer suporte da TI da TV1?"
                    options={[
                      {
                        label: "Sim",
                        value: "sim",
                      },
                      {
                        label: "Não",
                        value: "nao",
                      },
                    ]}
                  />
                </Col>
                <Col xl={6} xxl={4}>
                  <SelectField
                    name="useTv1Email"
                    label="Usará e-mail da TV1?"
                    options={[
                      {
                        label: "Sim",
                        value: "sim",
                      },
                      {
                        label: "Não",
                        value: "nao",
                      },
                    ]}
                  />
                </Col>
                <Col xl={6} xxl={4}>
                  <SelectField
                    name="equipmentFreelancers"
                    label="Equipamento"
                    options={[
                      {
                        label: "Próprio",
                        value: "OWN",
                      },
                      {
                        label: "TV1",
                        value: "TV1",
                      },
                    ]}
                  />
                </Col>
                <Col xl={6} xxl={4}>
                  <SelectField
                    name="equipmentTypeFreelancers"
                    label="Tipo de Equipamento"
                    options={[
                      {
                        label: "Notebook",
                        value: "NOTEBOOK",
                      },
                      {
                        label: "Desktop",
                        value: "DESKTOP",
                      },
                      {
                        label: "IMac",
                        value: "IMAC",
                      },
                      {
                        label: "MacBook",
                        value: "MACBOOK",
                      },
                    ]}
                  />
                </Col>
                <Col xl={6} xxl={4}>
                  <SelectField
                    name="workplace"
                    label="Local de trabalho"
                    options={[
                      {
                        label: "Escritório TV1",
                        value: "tv1_office",
                      },
                      {
                        label: "Externo",
                        value: "external",
                      },
                      {
                        label: "Home Office",
                        value: "home_office",
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Acesso a sistemas internos da TV1:</label>
                </Col>
                <Col>
                  <Checkbox.Group
                    name="accessInternalSystem"
                    options={options}
                    value={access}
                    onChange={onChange}
                  />
                </Col>
                <br />
                <br />
              </Row>
              <Row>
                <Col xl={12} xxl={12}>
                  <AreaField
                    name="othersAccessInternalSystem"
                    label="Outros (descreva)"
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={12} xxl={12}>
                  <TextField
                    name="otherMarketSoftware"
                    label="Outros softwares de mercado:"
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={12} xxl={12}>
                  <TextField
                    name="freelanceDirectManager"
                    label="Quem é o Gestor direto do Freelancer no período em que ele prestar serviço para a TV1?"
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6} xxl={4}>
                  <DateField
                    name="contractStartDate"
                    label="*Data do início do contrato."
                    disabled
                  />
                </Col>
                <Col xl={6} xxl={4}>
                  <DateField
                    name="contractEndDate"
                    label="*Data do fim do contrato."
                    disabled
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <Col xl={6} xxl={4}>
                    <CheckField
                      name="includeRenewal"
                      label="Incluir renovação por mais 90 dias"
                    />
                  </Col>
                </Col>
              </Row> */}

              <Button
                style={{ margin: "0 4px" }}
                htmlType="submit"
                onClick={() => setBtnClick("back")}
              >
                Voltar
              </Button>
              <Button
                style={{ margin: "0 4px" }}
                type="primary"
                htmlType="submit"
                loading={loading}
                onClick={() => setBtnClick("save")}
              >
                Salvar
              </Button>
            </>
          </Form>
        </div>
      </Section>
    </>
  );
};

export default FormFreelancers;
