import useRest from "hooks/useRest";
import { useState } from "react";
import {TProjectItem, TUpdateProjectItem} from "./index";

const useRequest = () => {
  const [loading, setLoading] = useState(false);
  const rest = useRest();

  const createRequests = async (params: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project-request";
    const response = await rest.post(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  const cancelRequest = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/manager/project-request/cancel/${id}`;
    const response = await rest.put(url);
    if (triggerLoading) setLoading(false);
    if (!response) return false;

    return true;
  };

  const downloadArchiveRequest = async (
    fileName: String,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project-request/download/" + fileName;
    const response = await rest.get(url, {
      responseType: "arraybuffer",
    });
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  const createRequestsFormData = async (
    body: any,
    headers: any,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project-request";
    const response = await rest.post(url, body, headers);
    if (triggerLoading) setLoading(false);
    if (!response) throw new Error();

    return response.result;
  };

  const startRequests = async (params: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project-request/start";
    const response = await rest.post(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  const findRequests = async (params: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project-request";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };
  const findRequestById = async (
    id: any,
    params: any,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/manager/project-request/${id}`;
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  const findProjects = async (params: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };
  const findProjectItems = async (params: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project-item";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  const findProjectItemsById = async (
      id: any,
      params: any,
      triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/manager/project-item/${id}`;
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  return {
    loading,
    findRequests,
    findRequestById,
    findProjects,
    findProjectItems,
    findProjectItemsById,
    startRequests,
    createRequests,
    createRequestsFormData,
    downloadArchiveRequest,
    cancelRequest,
  };
};

export default useRequest;
