import React, { useEffect, useMemo, useState } from 'react'
import {
  AreaField,
  Col,
  CurrencyField,
  HiddenField,
  NumberField,
  PageHeader,
  Row,
  SearchField,
  Section,
  SelectField
} from 'components'
import {
  useLocation,
  useNavigate,
  useParams
} from 'react-router'
import {
  Button,
  Form,
  message,
  Space
} from 'antd'
import {
  PurchaseOrderStatus,
  purchaseOrderStatusOptions,
  SupplierStatus
} from 'utils/constants'
import {
  TApportionsOrderItem,
  TApprovalHierarchy,
  TCompany,
  TCreatePurchaseOrder,
  TPaymentScheduleData,
  TProduct,
  TPurchaseOrder,
  TPurchaseOrderItems,
  TSubsidiary,
  TSupplierFormStepOne,
  useApprovalHierarchy,
  useProduct,
  usePurchaseOrder,
  useSuppliers
} from 'repositories'
import type { Rule } from 'antd/lib/form'
import { formatStringToNumber } from 'utils/helpers'
import printJS from 'print-js'
import useCostCenter from 'repositories/useCostCenter'
import { DefaultOptionType } from 'antd/lib/select'
import useCompany from 'repositories/useCompany'
import ItemList from '../Components/ItemList'
import ContractInfo from '../Components/ContractInfo'

type TPurchaseOrderForm = Omit<TPurchaseOrder, 'issueDate' | 'orderNumber' | 'creationUserName' | 'creationUserId' | 'questionateButton' | 'chat' | 'buyerName' | 'buyerId' | 'chatMember' | 'chatButton' | 'projectRequestId' | 'timeline' | 'items' | 'subsidiary'> & {
  subsidiaryId?: number
  companyId?: number
}

const initialValues: TPurchaseOrderForm = {
  description: '',
  payDay: 10,
  purchaseValue: 0,
  productId: 0,
  productName: '',
  supplierId: 0,
  projectRequest: '',
  supplierName: '',
  supplierType: '',
  approvalHierarchyId: 0,
  approvalHierarchyName: '',
  status: PurchaseOrderStatus.NEW,
  isRequestNotTiedToProject: false,
}

const validations: Record<string, Rule[]> = {
  description: [
    { required: true, message: 'Campo "Descrição" é obrigatório' },
  ],
  supplierName: [
    { required: true, message: 'Campo "Fornecedor" é obrigatório' },
  ],
  productName: [
    { required: true, message: 'Campo "Produto" é obrigatório' },
  ],
  approvalHierarchyName: [
    { required: true, message: 'Campo "Alçada" é obrigatório' },
  ],
  payDay: [
    { required: true, message: 'Campo "Dia de pagamento" é obrigatório' },
  ],
  companyId: [
    { required: true, message: 'Campo "Empresa de faturamento" é obrigatório' },
  ],
  subsidiaryId: [
    { required: true, message: 'Campo "Filial" é obrigatório' },
  ],
  purchaseValue: [
    { required: true, message: 'Campo "Valor do pedido" é obrigatório' },
    () => ({
      validator(_, minimumValue: string) {
        const formattedValue = formatStringToNumber(minimumValue)
        if (!minimumValue && formattedValue <= 0) {
          return Promise.reject(new Error('Valor do pedido deve ser maior que zero'))
        }

        return Promise.resolve()
      },
    })
  ],
}

const PurchaseOrderForm = () => {
  const [supplierValue, setSupplierValue] = useState('')
  const [suppliers, setSuppliers] = useState<TSupplierFormStepOne[]>([])
  const [supplierOptions, setSupplierOptions] = useState<{ value: string }[]>([])

  const [productValue, setProductValue] = useState('')
  const [products, setProducts] = useState<TProduct[]>([])
  const [productOptions, setProductOptions] = useState<{ value: string }[]>([])
  const [costCenterOptions, setCostCenterOptions] = useState<DefaultOptionType[]>([])
  const [companyOptions, setCompanyOptions] = useState<DefaultOptionType[]>([])
  const [subsidiaryOptions, setSubsidiaryOptions] = useState<DefaultOptionType[]>([])
  const [companies, setCompanies] = useState<TCompany[]>([])

  const [approvalHierarchyValue, setApprovalHierarchyValue] = useState('')
  const [approvalHierarchies, setApprovalHierarchies] = useState<TApprovalHierarchy[]>([])
  const [approvalHierarchyOptions, setApprovalHierarchyOptions] = useState<{ value: string }[]>([])

  const [apportions, setApportions] = useState<TPaymentScheduleData[]>([])
  const [items, setItems] = useState<TPurchaseOrderItems[]>([])

  const { pathname, state } = useLocation()
  const { id } = useParams<{ id: string }>()
  const purchaseRepository = usePurchaseOrder()
  const supplierRepository = useSuppliers()
  const productRepository = useProduct()
  const costCenterRepository = useCostCenter()
  const companyRepository = useCompany()
  const approvalHierarchyRepository = useApprovalHierarchy()
  const navigate = useNavigate()

  const [form] = Form.useForm<TPurchaseOrderForm>()
  const watchedSupplierId = Form.useWatch('supplierId', form)
  const watchedPayDay = Form.useWatch('payDay', form)
  const watchedPurchaseValue = Form.useWatch('purchaseValue', form)
  const watchedStatus = Form.useWatch('status', form)
  const watchedProductId = Form.useWatch('productId', form)
  const watchedNotTiedToProject = Form.useWatch('isRequestNotTiedToProject', form)
  const watchedCompanyId = Form.useWatch('companyId', form)

  useEffect(() => {
    const getCostCenter = async () => {
      const response = await costCenterRepository.findByFilterCostCenter({
        status: true,
        size: 1000,
        page: 0
      })
      if (!response) return
      const mappedCostCenter = response?.content?.map(item => ({
        value: item.id,
        label: `[${item.erpId}] ${item.description}`
      }) as DefaultOptionType)
      setCostCenterOptions(mappedCostCenter)
    }

    const getCompanies = async () => {
      const response = await companyRepository.findByFilterCompany()
      if (!response) return

      const mappedCompanies = response?.content?.map(item => ({
        value: item.id,
        label: item.name
      }) as DefaultOptionType)
      setCompanyOptions(mappedCompanies)
      setCompanies(response.content)
      return response.content
    }

    const getPurchaseOrder = async () => {
      const response = await purchaseRepository.findPurchaseOrderById(String(id))
      if (!response) return

      form.setFieldsValue(response)
      const mappedItems = response.items.map(item => ({
        ...item,
        ...item.costCenter ? {
          costCenterId: item.costCenter?.id,
          costCenterName: item.costCenter.description
        } : {}
      }))
      setItems(mappedItems)

      if (response.subsidiary) {
        const _companies = await getCompanies()
        const selectedCompany = _companies?.find(item => item.id === response.subsidiary.company.id)
        if (!selectedCompany) return
        form.setFieldsValue({
          companyId: response.subsidiary.company.id,
          subsidiaryId: response.subsidiary.id
        })
        setMappedSubsidiariesOptions(selectedCompany.subsidiaries)
      }

      const mappedApportions = mappedItems.flatMap(item => {
        const _apportions = item.apportions.map(apportion => ({
          id: apportion.id,
          dueDate: apportion.dueDate,
          value: apportion.value
        }) as TApportionsOrderItem)

        return {
          responsiblePayment: (
            response.isRequestNotTiedToProject
              ? item.costCenterName
              : item.projectName
          ),
          apportions: _apportions
        } as TPaymentScheduleData
      })
      setApportions(mappedApportions)
    }

    getCostCenter()
    if (id) getPurchaseOrder()
    if (!id) {
      getCompanies()
      form.setFieldsValue({
        isRequestNotTiedToProject: state.isRequestNotTiedToProject
      })
    }
  }, [id, state])

  const setMappedSubsidiariesOptions = (subsidiaries: TSubsidiary[]) => {
    const mappedSubsidiaries = subsidiaries.map(item => ({
      value: item.id,
      label: item.name
    }) as DefaultOptionType)
    setSubsidiaryOptions(mappedSubsidiaries)
  }

  const onChangeCompany = () => {
    const companyId = form.getFieldValue('companyId')
    form.setFieldValue('subsidiaryId', '')

    const selectedCompany = companies.find(item => item.id === companyId)
    if (!selectedCompany) return

    setMappedSubsidiariesOptions(selectedCompany.subsidiaries)
  }

  const onSearchSupplier = async (searchText: string) => {
    if (searchText.length < 3) return

    const response = await supplierRepository.findSupplierByFilter({
      corporateName: searchText,
      status: SupplierStatus.APPROVED,
      page: 0,
      size: 10
    })
    if (!response) return

    const mappedSupplierToOptions = response.content.map(item => ({ value: item.corporateName }))
    setSuppliers(response.content)
    setSupplierOptions(mappedSupplierToOptions)
  }

  const onChangeSupplier = (data: string) => {
    setSupplierValue(data)
    const findSupplier = suppliers.find(_supplier => _supplier.corporateName === data)
    if (!findSupplier) return

    form.setFieldsValue({
      supplierId: Number(findSupplier.id),
      supplierName: findSupplier.corporateName
    })
  }

  const onSearchProduct = async (searchText: string) => {
    if (searchText.length < 3) return

    const response = await productRepository.findProductByFilter({
      description: searchText,
      supplierId: form.getFieldValue('supplierId'),
      page: 0,
      size: 10
    })
    if (!response) return

    const mappedProductToOptions = response.content.map(item => ({ value: item.description }))
    setProducts(response.content)
    setProductOptions(mappedProductToOptions)
  }

  const onChangeProduct = (data: string) => {
    setProductValue(data)
    const findProduct = products.find(_product => _product.description === data)
    if (!findProduct) return

    form.setFieldsValue({
      productId: Number(findProduct.id),
      productName: findProduct.description
    })
  }

  const onSearchApprovalHierarchy = async (searchText: string) => {
    if (searchText.length < 3) return

    const response = await approvalHierarchyRepository.findByFilterApprovalHierarchy({
      name: searchText,
      page: 0,
      size: 10
    })
    if (!response) return

    const mappedApprovalHierarchyToOptions = response.content.map(item => ({ value: item.name }))
    setApprovalHierarchies(response.content)
    setApprovalHierarchyOptions(mappedApprovalHierarchyToOptions)
  }

  const onChangeApprovalHierarchy = (data: string) => {
    setApprovalHierarchyValue(data)
    const findApprovalHierarchy = approvalHierarchies.find(_approvalHierarchy => _approvalHierarchy.name === data)
    if (!findApprovalHierarchy) return

    form.setFieldsValue({
      approvalHierarchyId: Number(findApprovalHierarchy.id),
      approvalHierarchyName: findApprovalHierarchy.name
    })
  }

  const onBlurPurchaseValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!items.length) return

    const formattedValue = formatStringToNumber(e.target.value)
    const updatedItems = items.map(item => ({
      ...item,
      value: (item.percentage / 100) * formattedValue
    }))
    setItems(updatedItems)
  }

  const verifyPurchaseValue = (value: number) => {
    const stringValue = String(value)
    return stringValue.includes(',')
      ? formatStringToNumber(stringValue)
      : value
  }

  const onFinish = async (values: TPurchaseOrderForm) => {
    const totalPercentage = items.reduce((acc, curr) => acc + curr.percentage, 0)

    if (totalPercentage !== 100) {
      message.error('A soma do percentual não é de 100%')
      return
    }

    const data: Omit<TCreatePurchaseOrder, 'questionateButton' | 'creationUserId' | 'chat' | 'projectRequestId' | 'buyerName' | 'buyerId' | 'chatMember' | 'chatButton'> = {
      ...values,
      items,
      purchaseValue: verifyPurchaseValue(values.purchaseValue)
    }

    const response = id
      ? await purchaseRepository.updatePurchaseOrder({ ...data, id: Number(id) })
      : await purchaseRepository.createPurchaseOrder(data)

    if (!response) return
    message.success(
      id
        ? 'Requisição atualizada com sucesso'
        : 'Compra direta criada com sucesso'
    )
    onClickGoBack()
  }

  const sendToAnalysis = async () => {
    if (!id) return

    const response = await purchaseRepository.sendToAnalysisPurchaseOrder(id)
    if (!response) return

    message.success('Pedido enviado para análise')
    onClickGoBack()
  }

  const onClickGoBack = () => {
    navigate(-1)
  }

  const handlePrint = () => printJS('print', 'html')

  const {
    title,
    isContract,
    isView,
    action
  } = useMemo(() => {
    const [, , page] = pathname.split('/')
    const getTitle = () => {
      if (page === 'contrato') return 'Visualizar'
      if (page === 'visualizar') return 'Visualizar'
      if (id) return 'Editar'

      return 'Nova'
    }

    const _title = getTitle()

    return {
      title: (
        page === 'contrato'
          ? 'Visualizar contrato'
          : `${_title} compra direta`
      ),
      isContract: page === 'contrato',
      isView: page === 'visualizar',
      action: page
    }
  }, [id])

  return (
    <>
      <PageHeader
        title={title}
        breadcrumbItems={['Compra direta', action]}
      />
      <Section spacedTop>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <div id='print'>
            <Row>
              <Col xl={8} xxl={8}>
                <HiddenField
                  name='supplierId'
                />
                <HiddenField
                  name='isRequestNotTiedToProject'
                />
                <SearchField
                  required
                  disabled={isView}
                  name='supplierName'
                  label='Fornecedor'
                  value={supplierValue}
                  options={supplierOptions}
                  onChange={onChangeSupplier}
                  onSearch={onSearchSupplier}
                  placeholder='Pesquise pelo nome do fornecedor'
                  rules={validations.supplierName}
                  bordered={!isContract}
                />
              </Col>
              <Col xl={8} xxl={8}>
                <HiddenField
                  name='productId'
                />
                <SearchField
                  required
                  disabled={!watchedSupplierId || isView}
                  name='productName'
                  label='Produto'
                  value={productValue}
                  options={productOptions}
                  onChange={onChangeProduct}
                  onSearch={onSearchProduct}
                  placeholder='Pesquise pela descrição'
                  rules={validations.productName}
                  bordered={!isContract}
                />
              </Col>
              <Col xl={8} xxl={8}>
                <HiddenField
                  name='approvalHierarchyId'
                />
                <SearchField
                  required
                  disabled={isView}
                  label='Alçada'
                  name='approvalHierarchyName'
                  value={approvalHierarchyValue}
                  options={approvalHierarchyOptions}
                  onChange={onChangeApprovalHierarchy}
                  onSearch={onSearchApprovalHierarchy}
                  placeholder='Pesquise pelo nome da alçada'
                  rules={validations.approvalHierarchyName}
                  bordered={!isContract}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={8} xxl={8}>
                <NumberField
                  required
                  name='payDay'
                  label='Dia de pagamento'
                  bordered={!isContract}
                  disabled={isView}
                  max={31}
                />
              </Col>
              <Col xl={8} xxl={8}>
                <CurrencyField
                  required
                  disabled={isView}
                  name='purchaseValue'
                  label='Valor do pedido'
                  onBlur={onBlurPurchaseValue}
                  rules={validations.purchaseValue}
                  bordered={!isContract}
                />
              </Col>
              <Col xl={8} xxl={8}>
                <SelectField
                  required
                  disabled
                  name='status'
                  label='Status'
                  options={purchaseOrderStatusOptions}
                  bordered={!isContract}
                />
              </Col>
            </Row>
            {watchedNotTiedToProject && (
              <Row>
                <Col xl={8} xxl={8}>
                  <SelectField
                    required
                    disabled={isView || isContract}
                    name='companyId'
                    rules={validations.companyId}
                    label='Empresa de faturamento'
                    options={companyOptions}
                    bordered={!isContract}
                    onChange={onChangeCompany}
                  />
                </Col>
                <Col xl={8} xxl={8}>
                  <SelectField
                    required
                    label='Filial'
                    name='subsidiaryId'
                    disabled={!watchedCompanyId || isView || isContract}
                    options={subsidiaryOptions}
                    rules={validations.subsidiaryId}
                    bordered={!isContract}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <AreaField
                  required
                  name='description'
                  label='Descrição'
                  disabled={isView}
                  rules={validations.description}
                  maxLength={245}
                  bordered={!isContract}
                />
              </Col>
            </Row>
            {isContract && (
              <ContractInfo
                supplierId={watchedSupplierId}
                isRequestNotTiedToProject={watchedNotTiedToProject}
                initialProjects={items}
              />
            )}
            <ItemList
              items={items}
              productId={Number(watchedProductId)}
              setItems={setItems}
              purchaseValue={watchedPurchaseValue}
              payDay={watchedPayDay}
              apportions={apportions}
              isView={isView || isContract}
              costCenters={costCenterOptions}
              isRequestNotTiedToProject={watchedNotTiedToProject}
            />
          </div>
          {!isContract ? (
            <Form.Item className='mt-1'>
              <Space size='small'>
                <Button
                  onClick={onClickGoBack}
                  htmlType='button'
                >
                  Voltar
                </Button>
                <Button
                  disabled={purchaseRepository.loading || isView || watchedStatus !== PurchaseOrderStatus.NEW}
                  loading={purchaseRepository.loading}
                  type='primary'
                  htmlType='submit'
                >
                  Salvar
                </Button>
                <Button
                  disabled={purchaseRepository.loading || isView || !id}
                  loading={purchaseRepository.loading}
                  type='primary'
                  onClick={sendToAnalysis}
                >
                  Enviar para Análise
                </Button>
              </Space>
            </Form.Item>
          ) : (
            <Space
              size='small'
              className='mt-1'
            >
              <Button
                onClick={onClickGoBack}
                htmlType='button'
              >
                Voltar
              </Button>
              <Button
                type='primary'
                onClick={handlePrint}
              >
                Imprimir
              </Button>
            </Space>
          )}
        </Form>
      </Section>
    </>
  )
}

export default PurchaseOrderForm
