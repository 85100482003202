import React, { useEffect, useState } from "react";
import { Button, Input, Table, Form, Alert, Space, message } from "antd";
import { Section } from "components";
import { Navigate } from "react-router";
import { useRequest} from "repositories";
import { usePagination, useAuth } from "hooks";
import { FilterContainer } from "./styles";
import moment from "moment";

const ProjectColumns: any = [
  {
    title: "Id TOTVS",
    dataIndex: "idERP",
  },
  {
    title: "Unidade de Negócio [BU]",
    dataIndex: "buName",
  },
  {
    title: "Cliente",
    dataIndex: "clientName",
  },
  {
    title: "Tipo do Job",
    dataIndex: "projectTypeName",
  },
  {
    title: "Categoria Do Job",
    dataIndex: "projectCategoryName",
  },
  {
    title: "Nome do Projeto",
    dataIndex: "name",
  },
  {
    title: "Criado em:",
    dataIndex: "serviceStartDate",
    render: (_: any, record: any) => {
      return moment(record.serviceStartDate).format("DD/MM/YYYY");
    },
  },
  {
    title: "Vendido em:",
    dataIndex: "dateOfSale",
    render: (_: any, record: any) => {
      return moment(record.dateOfSale).format("DD/MM/YYYY");
    },
  },
  {
    title: "Entregue em:",
    dataIndex: "serviceEndDate",
    render: (_: any, record: any) => {
      return moment(record.serviceEndDate).format("DD/MM/YYYY");
    },
  },
];

const ProjectSelectionRequest = ({
  nextStep,
  prevStep,
  product,
  BU,
  projectSelected,
  projectSelectedIds,
}: any) => {
  const [project, setProject] = useState<any>([]);
  const [search, setSearch] = useState("");

  const [rowProjectSelected, setRowProjectSelected] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [alertMessage, setAlertMessage] = useState("");

  const { roles: authRoles, hasRole } = useAuth();
  const repository = useRequest();
  const pagination = usePagination();

  const requestProject = async () => {
    const currentPage = pagination.data.current ?? 0;
    const params: any = {
      clientStatus: true,
      buId: BU.id,
      productId: product.id,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: 10000,
    };

    const response = await repository.findProjects(params);
    if (!response) return;

    const mappedData = response.content
      .map((request: any) => ({
        id: String(request.id),
        key: request.id,
        ...request
      }))
    setProject(mappedData);
    pagination.changeTotalPages(response.totalPages);
  };

  useEffect(() => {
    if (pagination.data.current) requestProject();
  }, [pagination.data.current, pagination.data.pageSize, search]);

  const onSearch = (value: string) => {
    setSearch(value);
    pagination.onChange(1, pagination.data.pageSize ?? 1)
  };

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado" />;
  }

  const next = () => {
    if(rowProjectSelected?.length > 0) {
      projectSelected(rowProjectSelected)
      projectSelectedIds(selectedRowKeys)
      nextStep();
    }else {
      message.warning("Selecione pelo menos um projeto");
    }
  };

  const prev = () => {
    prevStep();
  };

  interface selectedRow {
    idERP: string;
    projectRevisionId: string;
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: selectedRow[]) => {
      setRowProjectSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <>
      <Section>
        <div style={{ marginTop: 24 }}>
          <Form layout="vertical">
            <>
              <FilterContainer>
                <Input.Search
                  placeholder="Pesquise pelo ID totvs do projeto"
                  onSearch={onSearch}
                  enterButton
                />
              </FilterContainer>
              <Table
                rowSelection={{
                  type: "checkbox",
                  selectedRowKeys,
                  ...rowSelection,
                }}
                columns={ProjectColumns}
                dataSource={project}
                pagination={{ showSizeChanger: true }}
              />

              {alertMessage ? (
                <Space
                  direction="vertical"
                  style={{ width: "100%", marginBottom: "15px" }}
                >
                  <Alert message={alertMessage} type="error" />
                </Space>
              ) : (
                <></>
              )}

              <Button onClick={() => prev()}>Voltar</Button>
              <Button
                type="primary"
                style={{ margin: "0 8px" }}
                onClick={() => next()}
              >
                Próximo
              </Button>
            </>
          </Form>
        </div>
      </Section>
    </>
  );
};

export default ProjectSelectionRequest;
