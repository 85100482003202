import React, { useEffect, useState } from "react";
import { Button, Input, Table, Form, Alert, Space } from "antd";
import { Section } from "components";
import { Navigate, useNavigate } from "react-router";
import { useRequest } from "repositories";
import { usePagination, useAuth } from "hooks";
import { FilterContainer } from "./styles";
import moment from "moment";

const ProjectColumns: any = [
  // {
  //   title: "Id Compras",
  //   dataIndex: "id",
  // },
  {
    title: "Id Projeto",
    dataIndex: "idERP",
  },
  {
    title: "Unidade de Negócio [BU]",
    dataIndex: "buName",
  },
  {
    title: "Cliente",
    dataIndex: "clientName",
  },
  {
    title: "Tipo do Job",
    dataIndex: "projectTypeName",
  },
  {
    title: "Categoria Do Job",
    dataIndex: "projectCategoryName",
  },
  {
    title: "Nome do Projeto",
    dataIndex: "projectName",
  },
  {
    title: "Fase CRM",
    dataIndex: "crmFase",
  },
  {
    title: "Fase TOTVS",
    dataIndex: "totvsFase",
  },
  {
    title: "Criado em:",
    dataIndex: "serviceStartDate",
    render: (_: any, record: any) => {
      return moment(record.serviceStartDate).format("DD/MM/YYYY");
    },
  },
  {
    title: "Vendido em:",
    dataIndex: "dateOfSale",
    render: (_: any, record: any) => {
      return moment(record.dateOfSale).format("DD/MM/YYYY");
    },
  },
  {
    title: "Entregue em:",
    dataIndex: "serviceEndDate",
    render: (_: any, record: any) => {
      return moment(record.serviceEndDate).format("DD/MM/YYYY");
    },
  },
];

const ProjectSelectionRequest = ({
  nextStep,
  projectSelected,
  currentProject,
}: any) => {
  const [project, setProject] = useState<any>([]);
  const [search, setSearch] = useState("");

  const [rowProjectSelected, setRowProjectSelected] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [alertMessage, setAlertMessage] = useState("");

  const { roles: authRoles, hasRole } = useAuth();
  const repository = useRequest();
  const navigate = useNavigate();
  const pagination = usePagination();

  const requestClient = async () => {
    const currentPage = pagination.data.current ?? 0;
    const params: any = {
      search: search,
      clientStatus: true,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: 10000,
    };

    const response = await repository.findProjects(params);
    if (!response) return;

    const mappedData = response.content
      .map((request: any) => ({
        id: String(request.id),
        key: request.id,
        idERP: request.idERP,
        projectName: request.name,
        projectRevisionId: String(request.projectRevisionId),
        buName: request.buName,
        clientName: request.clientName,
        crmFase: request.phaseCrm,
        totvsFase: request.phaseTotvs,
        dateOfSale: request.dateOfSale,
        jobType: "Não identificado",
        projectCategoryName: request.projectCategoryName,
        projectTypeName: request.projectTypeName,
        // necessária refatoração ou averiguação da veracidade do campo abaixo
        serviceEndDate: request.inclusionDate,
        serviceStartDate: request.createdDate,
        subsidiaryId: request.subsidiaryIdErp,
        typeRequest: "Simples",
        categoria: "Compra & Contratação",
      }))
      .filter(
        (item: { totvsFase: string }) =>
          !["Fechamento (Fin)", "Cancelado", "Finalizado"].includes(
            item.totvsFase
          )
      );

    setProject(mappedData);
    if (currentProject.key) {
      setSelectedRowKeys([currentProject.key]);
      setRowProjectSelected(currentProject);
    }
    pagination.changeTotalPages(response.totalPages);
  };

  useEffect(() => {
    if (pagination.data.current) requestClient();
  }, [pagination.data.current, pagination.data.pageSize, search]);

  const onSearch = (value: string) => {
    setSearch(value);
    pagination.onChange(1, pagination.data.pageSize ?? 1)
  };

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado" />;
  }

  const next = () => {
    if (!(Object.keys(rowProjectSelected).length === 0)) {
      projectSelected(rowProjectSelected);
      nextStep();
    } else {
      setAlertMessage("Selecione um projeto!");
    }
  };

  const prev = () => {
    navigate("/");
  };

  interface selectedRow {
    idERP: string;
    projectRevisionId: string;
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: selectedRow[]) => {
      setAlertMessage("");
      setRowProjectSelected(selectedRows[0]);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <>
      <Section>
        <div style={{ marginTop: 24 }}>
          <Form layout="vertical">
            <>
              <FilterContainer>
                <Input.Search
                  placeholder="Pesquise pelo nome ou id"
                  onSearch={onSearch}
                  enterButton
                />
              </FilterContainer>
              <Table
                rowSelection={{
                  type: "radio",
                  selectedRowKeys,
                  ...rowSelection,
                }}
                columns={ProjectColumns}
                dataSource={project}
                pagination={{ showSizeChanger: true }}
              />

              {alertMessage ? (
                <Space
                  direction="vertical"
                  style={{ width: "100%", marginBottom: "15px" }}
                >
                  <Alert message={alertMessage} type="error" />
                </Space>
              ) : (
                <></>
              )}

              <Button onClick={() => prev()}>Cancelar</Button>
              <Button
                type="primary"
                style={{ margin: "0 8px" }}
                onClick={() => next()}
              >
                Próximo
              </Button>
            </>
          </Form>
        </div>
      </Section>
    </>
  );
};

export default ProjectSelectionRequest;
