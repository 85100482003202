import React, { useEffect, useMemo, useRef, useState } from "react";
import { v4 as genId } from "uuid";
import { Button, Drawer, Input, Spin } from "antd";
import { TMemberChat, TMessage, useChat } from "repositories";
import { useAuth } from "hooks";
import { BsCheck, BsCheckAll } from "react-icons/bs";
import { formatDateTimeToView } from "utils/helpers";
import { FlexCenterContainer } from "components";
import { ChatMessage } from "./styles";
import moment from "moment";
import { throttle } from "lodash";

type TProps = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<boolean>;
  projectRequestId?: number;
  purchaseOrderId?: number;
  refreshCallback?: () => Promise<void>;
  setMember: React.Dispatch<TMemberChat>;
  member: TMemberChat;
};

const Chat = (props: any) => {
  const {
    isVisible,
    setIsVisible,
    projectRequestId,
    setMember,
    member,
    purchaseOrderId,
    refreshCallback,
  } = props;
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [message, setMessage] = useState("");

  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [chatIdClone, setChatIdClone] = useState<any>(0);

  const [isFirst, setIsFirst] = useState(true);
  const [loopId, setLoopId] = useState<any>([]);

  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const messagesRef = useRef<HTMLDivElement>(null);
  const chatRepository = useChat();
  const { userData } = useAuth();

  const onClose = () => {
    setIsVisible(false);
    setMessages([]);
    loopId.forEach(async (element: any) => {
      await clearInterval(element);
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setMessage(value);
  };

  const sortMessagesById = (arr: TMessage[]) =>
    arr.sort((old, after) => (Number(old.id) < Number(after.id) ? -1 : 1));

  const createChat = async () => {
    const castName = member.userToName?.substring(0, 30);
    const response = await chatRepository.createChat({
      projectRequestId,
      purchaseOrderId,
      name: `Chat (${castName}...)`,
    });
    if (!response) return;

    await chatRepository.addUserToChat({
      chatId: response.id,
      usersId: [Number(member.userToId)],
    });

    setMember({
      ...member,
      chatId: response.id,
    });

    setChatIdClone(response.id);

    if (refreshCallback) {
      await refreshCallback();
    }

    return response.id;
  };

  const sendMessage = async () => {
    if (!message) return;
    const cloneMember = { ...member };

    if (chatIdClone === 0) {
      const chatId = await createChat();
      cloneMember.chatId = chatId;
    }
    setIsSendingMessage(true);
    const response = await chatRepository.sendMessage({
      chatId: Number(cloneMember.chatId),
      message,
    });
    if (!response) return;

    const newMessage: TMessage = {
      id: response.messageId,
      userId: Number(userData.userId),
      name: userData.userName,
      createdDate: moment(new Date()).format("DD/MM/YYYY HH:MM"),
      message,
    };
    setMessage("");
    setMessages([...messages, newMessage]);
    scrollToBottom();
    // if (loopId) {
    //   clearInterval(loopId);
    //   getMessages();
    // }
    setIsSendingMessage(false);
  };
  const getMessages = async () => {
    if (isFirst) {
      setIsLoadingMessage(true);

      const responseClone = await chatRepository.findMessagesByFilter({
        chatId: Number(chatIdClone),
        page,
        size: -1,
        sort: "id,DESC",
      });

      await chatRepository.confirmMessagesByChatId(Number(chatIdClone));

      setIsLoadingMessage(false);

      if (!responseClone) return;

      const formattedMessagesClone = responseClone.content?.map(
        (mappedMessage: any) => ({
          ...mappedMessage,
          name: mappedMessage.userName,
          createdDate: moment(mappedMessage.createdDate).format(
            "DD/MM/YYYY HH:mm"
          ),
          readDate: mappedMessage.messageInfo[0]?.readDate,
        })
      );

      setMessages(sortMessagesById([...messages, ...formattedMessagesClone]));

      scrollToBottom();

      const loopMessages = setInterval(async () => {
        if (!isVisible) {
          loopId.forEach(async (element: any) => {
            await clearInterval(element);
          });
          return;
        }

        await chatRepository.confirmMessagesByChatId(Number(chatIdClone));
        const response = await chatRepository.findMessagesByFilter({
          chatId: Number(chatIdClone),
          page,
          size: -1,
          sort: "id,DESC",
        });
        if (!response) return;

        const formattedMessages = response.content?.map(
          (mappedMessage: any) => ({
            ...mappedMessage,
            name: mappedMessage.userName,
            createdDate: moment(mappedMessage.createdDate).format(
              "DD/MM/YYYY HH:mm"
            ),
            readDate: mappedMessage.messageInfo[0]?.readDate,
          })
        );

        setMessages(sortMessagesById([...messages, ...formattedMessages]));

        const drawerElement: any = document.querySelector(
          ".dr .ant-drawer-body"
        );

        if (
          drawerElement?.scrollTop + drawerElement?.clientHeight ===
          drawerElement?.scrollHeight
        ) {
          scrollToBottom();
        }
      }, 5000);
      setLoopId([...loopId, loopMessages]);
      setIsFirst(false);
    }
  };

  const onClickLoadMore = () => setPage(page + 1);

  const scrollToBottom = () => {
    messagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    console.log(isSendingMessage);
    if (!isSendingMessage) {
      sendMessage();
    }
  };

  // useEffect(() => {
  //   if (page === 0) scrollToBottom();
  // }, [messages, page]);

  useEffect(() => {
    setChatIdClone(member.chatId ? member.chatId : 0);
    setIsFirst(true);

    if (isVisible && chatIdClone > 0) getMessages();
  }, [isVisible, chatIdClone]);

  useEffect(() => {
    const readMessages = async () => {
      const chatId = Number(chatIdClone);
      await chatRepository.confirmViewer(chatId);
      setMember({
        ...member,
        messagesNotViewed: 0,
      });
    };

    if (isVisible && member.chatId && member.messagesNotViewed) readMessages();
  }, [member, isVisible]);

  const currentPage = useMemo(() => page + 1, [page]);

  const isViewButtonLoadMore = useMemo(
    () => currentPage !== totalPage && totalPage > 1,
    [currentPage, totalPage]
  );

  const footer = (
    <>
      <Input
        showCount
        maxLength={-1}
        value={message}
        onChange={onChange}
        onKeyPress={handleEnterKeyPress}
        placeholder="Digite para enviar uma mensagem"
      />
      <Button
        type="primary"
        className="mt-1"
        loading={chatRepository.loading}
        disabled={!message}
        onClick={sendMessage}
      >
        Enviar
      </Button>
    </>
  );

  return (
    <Drawer
      title="Chat"
      placement="right"
      size="large"
      bodyStyle={{
        padding: "5px",
      }}
      className="dr"
      onClose={onClose}
      visible={isVisible}
      footer={footer}
      destroyOnClose
    >
      {isLoadingMessage ? (
        <FlexCenterContainer>
          <Spin />
        </FlexCenterContainer>
      ) : (
        <>
          {/* {isViewButtonLoadMore && (
            <FlexCenterContainer>
              <Button
                type="text"
                onClick={onClickLoadMore}
                loading={chatRepository.loading}
              >
                Carregar mais mensagens
              </Button>
            </FlexCenterContainer>
          )} */}
          {messages.map((item) => {
            const key = genId();
            const myMessage = item.userId === member.userToId;
            return (
              <ChatMessage key={key} alignRight={!myMessage}>
                <p>{item.name}</p>
                <span>{item.message}</span>
                <div>
                  <time>{item.createdDate}</time>
                  {item.readDate ? <BsCheckAll /> : <BsCheck />}
                </div>
              </ChatMessage>
            );
          })}
        </>
      )}
      <div ref={messagesRef} />
    </Drawer>
  );
};

export default Chat;
