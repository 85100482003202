import React, { useEffect, useMemo, useState } from "react";
import { Form, message, Modal } from "antd";
import {
  TCreateOrUpdateProductPrice,
  TProductPreciary,
  TProductRangePriceForm,
  useProduct,
  useSuppliers,
} from "repositories";
import { formatCurrencyToServer, formatStringToNumber } from "utils/helpers";
import { Rule } from "antd/lib/form";
import {
  AreaField,
  CheckField,
  Col,
  CurrencyField,
  HiddenField,
  Row,
  SearchField,
  TextField,
} from "components";

const initialValues: TProductRangePriceForm = {
  id: "",
  supplierId: "",
  productId: "",
  productName: "",
  minimumPrice: "",
  maximumPrice: "",
  comment: "",
  preciary: false,
};

const validations: Record<string, Rule[]> = {
  productName: [{ required: true, message: 'Campo "Produto" é obrigatório' }],
  minimumPrice: [
    ({ getFieldValue }) => ({
      validator(_, minimumPrice: string) {
        if (!minimumPrice && getFieldValue("preciary")) {
          return Promise.reject(
            new Error('Campo "Preço mínimo" é obrigatório')
          );
        }

        return Promise.resolve();
      },
    }),
    ({ getFieldValue }) => ({
      validator(_, minimumPrice: string) {
        if (!getFieldValue("preciary")) return Promise.resolve();

        const _minimumPrice = formatStringToNumber(minimumPrice);

        const maximumPrice = getFieldValue("maximumPrice");
        const _maximumPrice = formatStringToNumber(maximumPrice);

        if (_minimumPrice > _maximumPrice) {
          return Promise.reject(
            new Error("Preço mínimo não pode ser maior que o preço máximo")
          );
        }

        return Promise.resolve();
      },
    }),
  ],
  maximumPrice: [
    ({ getFieldValue }) => ({
      validator(_, maximumPrice: string) {
        if (!maximumPrice && getFieldValue("preciary")) {
          return Promise.reject(
            new Error('Campo "Preço máximo" é obrigatório')
          );
        }

        return Promise.resolve();
      },
    }),
  ],
};

type TProps = {
  productId: number;
  supplierId: number;
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  initialProducts: any;
  actionFn(): Promise<void>;
};

const RelatedProductForm = (props: TProps) => {
  const {
    productId,
    supplierId,
    isModalVisible,
    setIsModalVisible,
    initialProducts,
    actionFn,
  } = props;
  const [value, setValue] = useState("");
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [products, setProducts] = useState<TProductPreciary[]>([]);

  const productRepository = useProduct();
  const supplierRepository = useSuppliers();
  const [form] = Form.useForm<TProductRangePriceForm>();
  const hasPreciary = Form.useWatch("preciary", form);

  const productAlreadyVinculed = () => {
    if (productId) return false;
    const id = form.getFieldValue("productId");
    const alreadyVinculed = initialProducts.some(
      (product: any) => product.id === id
    );
    if (alreadyVinculed) {
      message.error("Produto já vinculado a este fornecedor!");
      return true;
    }

    return false;
  };

  const updatePreciary = async (values: TProductRangePriceForm) => {
    values.value = Number(values.value);
    const data: TCreateOrUpdateProductPrice = {
      ...values,
      supplierId,
      minimumPrice: formatCurrencyToServer(String(0)), // Mudança no layout
      maximumPrice: formatCurrencyToServer(String(1)), // Mudança no layout
    };
    const response = await supplierRepository.createOrUpdateProductRangePrice(
      data
    );
    if (!response) return;

    message.success("Preciário atualizado com sucesso!");
    actionFn();
    onResetForm();
  };

  const formatCurrencyToServer = (number: string) => {
    if (typeof number === "number") return number;
    if (!number) return "0";

    if (number.indexOf(",")) {
      return number.replace(/\./g, "").replace(",", ".").replace(" ", "");
    }

    return number.trim();
  };

  const onFinish = async (values: any) => {
    // console.log(formatCurrencyToServer(form.getFieldValue("value")));
    if (!productAlreadyVinculed()) {
      // console.log("here2");
      const remainingProducts = initialProducts.map(
        (initialProduct: any) => initialProduct.id
      );
      if (!productId) {
        await supplierRepository.updateProductToSupplier({
          supplierId,
          products: [...remainingProducts, Number(values.productId)],
        });

        message.success("Produto vinculado com sucesso!");
        actionFn();
        onResetForm();
      }
      // console.log({ values });
      values.value = formatCurrencyToServer(form.getFieldValue("value"));
      if (productId) await updatePreciary(values);
      handleClose();
    }
  };

  const onSearch = async (searchText: string) => {
    if (searchText.length < 3) return;

    const response = await productRepository.findProductByFilter({
      description: searchText,
      page: 0,
      size: 10,
    });
    if (!response) return;

    const productOptions = response.content.map((item) => ({
      value: item.description,
    }));
    setProducts(response.content);
    setOptions(productOptions);
  };

  const onChange = (data: string) => {
    setValue(data);
    const findProduct = products.find(
      (product) => product.description === data
    );
    if (!findProduct) return;

    form.setFieldsValue({
      productId: findProduct.id,
      productName: findProduct.description,
      preciary: findProduct.preciary,
    });
  };

  const onResetForm = () => {
    setValue("");
    setOptions([]);
    form.resetFields();
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const getProductWithPreciary = () => {
    if (productId) {
      const _product = initialProducts.find(
        (product: any) => product.id === productId
      );
      if (!_product) return;

      // console.log(_product);

      const data = {
        id: _product.preciaryId ?? "",
        supplierId: Number(supplierId),
        productId: _product.id,
        productName: _product.description,
        minimumPrice: _product.minimumPrice ?? 0,
        maximumPrice: _product.maximumPrice ?? 0,
        comment: _product.comment ?? "",
        preciary: true,
        value: _product?.value ? _product?.value : 0,
        unitOfMeasurement: _product?.unitOfMeasurement
          ? _product?.unitOfMeasurement
          : "",
      };
      form.setFieldsValue(data);
    } else {
      setValue("");
      form.resetFields();
      const data = {
        value: 0,
        unitOfMeasurement: "",
      };
      form.setFieldsValue(data);
    }
  };

  useEffect(() => {
    if (isModalVisible && supplierId) getProductWithPreciary();
  }, [productId, supplierId, isModalVisible]);

  const title = useMemo(
    () => (productId ? "Editar Preciário" : "Vincular Produto"),
    [productId]
  );

  const onChangePreciary = (data: any) => {
    // console.log({ data });
  };

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onOk={handleSubmit}
      okText="Salvar"
      cancelText="Fechar"
      confirmLoading={supplierRepository.loading}
      onCancel={handleClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <HiddenField name="preciary" checkbox />
        <HiddenField name="productId" />
        <Row>
          <Col xl={24}>
            <SearchField
              required
              value={value}
              name="productName"
              label="Produto"
              disabled={!!productId}
              options={options}
              onSearch={onSearch}
              onChange={onChange}
              isLoading={productRepository.loading}
              placeholder="Pesquise pelo nome do produto"
              rules={validations.productName}
            />
          </Col>
        </Row>
        {productId !== 0 ? (
          <Row>
            <Col md={12}>
              <CurrencyField label="Valor" name="value" />
            </Col>
            <Col md={12}>
              <TextField label="Unidade de medida" name="unitOfMeasurement" />
              <CheckField
                name="preciary"
                label="Preciário ?"
                onChange={onChangePreciary}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
};

export default RelatedProductForm;
