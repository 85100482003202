import React, { useEffect, useState } from "react";
import { Form, List } from "antd";
import { IoIosArrowDown, IoIosArrowBack } from "react-icons/io";
import { useAuth } from "hooks";
import { Container, Header, Content } from "./styles";
import { Col, Row, TextField } from "../../../../components";
import { useProject, useProjectItems } from "../../../../repositories";
import {
  formatCurrency,
  formatDateToView,
  formatToPercent,
} from "../../../../utils/helpers";

const ProjectAndLineInformation = ({ projectRequest, typeRequest }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [projects, setProjects] = useState<any>({});
  const [form] = Form.useForm<any>();
  const projectRepository = useProject();
  const projectItemsRepository = useProjectItems();
  const { userData } = useAuth();
  // const project = projectRequest[0]?.projectRequest?.items[0]?.projectRevision?.project;
  // const projectId = project?.id !== undefined ? project.id : null;
  const handleOpen = () => setIsOpen(!isOpen);

  const getProjectAndLineInformation = async () => {
    const response = await projectRepository.findProjectRevisionByFilter({
      projectId:
        projectRequest[0]?.projectRequest?.items[0]?.projectRevision?.project
          ?.id,
    });
    // const responseLine = await projectItemsRepository.findProjectItemsByFilter({
    //   projectId: projectRequest[0].projectRequest.items[0].projectRevision.project.id,
    //   projectRevisionId: projectRequest[0].projectRequest.items[0].projectRevision.id
    // })

    const mappedDataProject = response.map((project: any) => ({
      ...project,
      indicators: project.indicators,
    }));
    setProjects(mappedDataProject[0]);
    form.setFieldsValue({
      id: projects.project?.idERP,
      saleTv1: formatCurrency(projects.indicators?.sale?.tv1),
      budgetedTv1Tax: formatCurrency(projects.indicators?.news?.budgetedTv1Tax),
      budgetedTv1LiquidSale: formatCurrency(
        projects.indicators?.news?.budgetedTv1LiquidSale
      ),
      budgetedTv1ExternalCost: formatCurrency(
        projects.indicators?.news?.budgetedTv1ExternalCost
      ),
      grossMarginSold: formatCurrency(
        projects.indicators?.news?.grossMarginSold
      ),
      percGrossMarginSold: formatToPercent(
        projects.indicators?.news.percGrossMarginSold
      ),
      soldTv1Tax: formatCurrency(projects.indicators?.news?.soldTv1Tax),
      currentTv1LiquidSale: formatCurrency(
        projects.indicators?.news?.currentTv1LiquidSale
      ),
      currentTv1ExternalCost: formatCurrency(
        projects.indicators?.news?.currentTv1ExternalCost
      ),
      currentGrossMargin: formatCurrency(
        projects.indicators?.news?.currentGrossMargin
      ),
      percCurrentGrossMargin: formatToPercent(
        projects.indicators?.news.percCurrentGrossMargin
      ),
      idLine: projectRequest[0].projectRequest.items[0]?.lineIdErp,
      budgetCostLine: formatCurrency(
        projectRequest[0]?.projectRequest.items[0]?.totalCostLineProject
      ),
      sellValueLine: formatCurrency(projectRequest[0]?.projectRequest.items[0]?.totalSale),
      sellMarginLine: projectRequest[0]?.projectRequest.items[0]?.salesMargin,
      currentCostLine: projectRequest[0]?.projectRequest.items[0]?.unitSale,
      currentMarginLine:
        projectRequest[0]?.projectRequest.items[0]?.salesMargin,
    });
  };

  const initialValues = {
    id: "",
    saleTv1: "",
    budgetedTv1Tax: "",
    budgetedTv1LiquidSale: "",
    budgetedTv1ExternalCost: "",
    grossMarginSold: "",
    percGrossMarginSold: "",
    soldTv1Tax: "",
    currentTv1LiquidSale: "",
    currentTv1ExternalCost: "",
    currentGrossMargin: "",
    percCurrentGrossMargin: "",
    idLine: "",
    budgetCostLine: "",
    sellValueLine: "",
    sellMarginLine: "",
    currentCostLine: "",
    currentMarginLine: "",
  };
  useEffect(() => {
    getProjectAndLineInformation();
  }, [isOpen]);

  return (
    <Container>
      <Header onClick={handleOpen}>
        <text>Informação do projeto e linha(s)</text>
        {isOpen ? <IoIosArrowDown /> : <IoIosArrowBack />}
      </Header>
      {isOpen && (
        <Content>
          <List size="small">
            <Form form={form} layout="vertical" initialValues={initialValues}>
              <text>Projeto</text>
              <Row>
                {/*<Col md={8} xl={8} xxl={8}>*/}
                {/*  <TextField disabled name="id" label="Id" />*/}
                {/*</Col>*/}
                <Col md={8} xl={8} xxl={8}>
                  <TextField
                    disabled
                    name="saleTv1"
                    label="Valor de Venda TV1"
                  />
                </Col>
                {/*<Col md={8} xl={8} xxl={8}>*/}
                {/*  <TextField*/}
                {/*    disabled*/}
                {/*    name="budgetedTv1Tax"*/}
                {/*    label="Imposto venda TV1"*/}
                {/*  />*/}
                {/*</Col>*/}
                {/*<Col md={8} xl={8} xxl={8}>*/}
                {/*  <TextField*/}
                {/*    disabled*/}
                {/*    name="budgetedTv1LiquidSale"*/}
                {/*    label="Receita líquida orçada TV1"*/}
                {/*  />*/}
                {/*</Col>*/}
                {/*<Col md={8} xl={8} xxl={8}>*/}
                {/*  <TextField*/}
                {/*    disabled*/}
                {/*    name="budgetedTv1ExternalCost"*/}
                {/*    label="Custos externos orçados TV1"*/}
                {/*  />*/}
                {/*</Col>*/}
                {/*<Col md={8} xl={8} xxl={8}>*/}
                {/*  <TextField*/}
                {/*    disabled*/}
                {/*    name="grossMarginSold"*/}
                {/*    label="Margem Bruta vendida R$"*/}
                {/*  />*/}
                {/*</Col>*/}
                <Col md={8} xl={8} xxl={8}>
                  <TextField
                    disabled
                    name="percGrossMarginSold"
                    label="MB vendida %"
                  />
                </Col>
                {/*<Col md={8} xl={8} xxl={8}>*/}
                {/*  <TextField*/}
                {/*    disabled*/}
                {/*    name="soldTv1Tax"*/}
                {/*    label="Imposto projetado (corrente) TV1"*/}
                {/*  />*/}
                {/*</Col>*/}
                {/*<Col md={8} xl={8} xxl={8}>*/}
                {/*  <TextField*/}
                {/*    disabled*/}
                {/*    name="currentTv1LiquidSale"*/}
                {/*    label="Receita líquida corrente TV1"*/}
                {/*  />*/}
                {/*</Col>*/}
                {/*<Col md={8} xl={8} xxl={8}>*/}
                {/*  <TextField*/}
                {/*    disabled*/}
                {/*    name="currentTv1ExternalCost"*/}
                {/*    label="Custos externos correntes TV1"*/}
                {/*  />*/}
                {/*</Col>*/}
                {/*<Col md={8} xl={8} xxl={8}>*/}
                {/*  <TextField*/}
                {/*    disabled*/}
                {/*    name="currentGrossMargin"*/}
                {/*    label="Margem Bruta corrente R$"*/}
                {/*  />*/}
                {/*</Col>*/}
                <Col md={8} xl={8} xxl={8}>
                  <TextField
                    disabled
                    name="percCurrentGrossMargin"
                    label="MB corrente %"
                  />
                </Col>
              </Row>
              {
                typeRequest === 'SIMPLE' ?
                  <>
                    <text>Linha(s)</text>
                    <Row>
                      <Col md={8} xl={8} xxl={8}>
                        <TextField disabled name="idLine" label="Id" />
                      </Col>
                      <Col md={8} xl={8} xxl={8}>
                        <TextField
                          disabled
                          name="budgetCostLine"
                          label="Custo orçado"
                        />
                      </Col>
                      <Col md={8} xl={8} xxl={8}>
                        <TextField
                          disabled
                          name="sellValueLine"
                          label="Valor da venda"
                        />
                      </Col>
                      {/* <Col md={8} xl={8} xxl={8}>
                          <TextField disabled name="sellMarginLine" label="Margem venda" />
                      </Col>
                      <Col md={8} xl={8} xxl={8}>
                          <TextField disabled name="currentCostLine" label="Custo corrente" />
                      </Col>
                      <Col md={8} xl={8} xxl={8}>
                          <TextField disabled name="currentMarginLine" label="Margem corrente" />
                      </Col> */}
                    </Row>
                  </>
                :
                  <></>
              }
            </Form>
          </List>
        </Content>
      )}
    </Container>
  );
};

export default ProjectAndLineInformation;
