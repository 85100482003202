import { Popover as AntPopover } from 'antd'
import React from 'react'

type TProps = {
  setNames: Set<string>
  title: string
  fontSize?: string
  ellipsis?: number
}

const Popover = ({
  setNames,
  title,
  fontSize = '16px',
  ellipsis = 70
}: TProps) => {
  const style = {
    fontSize
  }

const styleBadge = {
  background: 'black',
  color: 'white',
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '5px',
  fontSize: '11px'
}

  const truncate = (str: string, n: number) => ((str && str.length > n) ? `${str.slice(0, n - 1)}...` : str)

  const GetFirstName = (props: any) => {

    const iterator = props.set.values()

    return <>
      { props.set.size > 1 ?
        <p style={style}>{truncate(iterator.next().value, ellipsis)} 
          <span style={styleBadge}>+{props.set.size - 1}</span>
        </p>
       :
        <p style={style}>{truncate(iterator.next().value, ellipsis)}</p>
      }
    </>

  }

  const getContent = (set: Set<string>) => {
    const getAllNames = (_set: Set<string>) => {
      const iterator = _set.values()
      const arrayStrings: string[] = []
      set.forEach(() => {
        arrayStrings.push(iterator.next().value)
      })
      return arrayStrings
    }

    const namesArray = getAllNames(set)

    return (
      <ul>
        {namesArray.map(name => (
          <li key={name}>
            {name}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <AntPopover content={getContent(setNames)} title={title}>
      <p><GetFirstName set={setNames} /></p>
    </AntPopover>
  )
}


export default Popover
