import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Button, Form, Image, message, Modal, Upload } from "antd";
import {
  TFindUserParams,
  TProfileForm,
  TUserArea,
  TUserFunction,
  useCommon,
  useProfile,
  useUserGroups,
  useUsers,
} from "repositories";
import { useAuth } from "hooks";
import { MaskPhone, Status } from "utils/constants";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import { fileToBase64 } from "utils/helpers";
import type { Rule } from "antd/lib/form";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import MaskedField from "components/Form/MaskedField";
import HiddenField from "components/Form/HiddenField";
import TextField from "components/Form/TextField";
import SelectField from "components/Form/SelectField";
import CheckField from "components/Form/CheckField";
import DateField from "components/Form/DateField";
import type { DefaultOptionType } from "antd/lib/select";
import moment from "moment";

const initialValues = {
  id: "",
  name: "",
  email: "",
  telephone: "",
  userGroupId: "",
  userGroupName: "",
  userFunctionId: "",
  userAreaId: "",
  picture: "",
};

const validations: Record<string, Rule[]> = {
  name: [{ required: true, message: 'Campo "Nome" é obrigatório' }],
  periodAbsenceFrom: [{ required: true, message: "Campo é obrigatório" }],
};

const convertToOptions = (items: TUserArea[] | TUserFunction[]) =>
  items.map(
    (item) =>
      ({
        label: item.name,
        value: item.id,
      } as DefaultOptionType)
  );

const acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
const messageInvalidFileType =
  "Este formato de arquivo não é aceito, por favor, tente novamente.";
const messageExceededFileSize = "A imagem não pode ser maior que 8MB";

type TProps = {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<boolean>;
};

const ProfileForm = ({ isModalVisible, setIsModalVisible }: TProps) => {
  const [groupOptions, setGroupOptions] = useState<DefaultOptionType[]>([]);
  const [areaOptions, setAreaOptions] = useState<DefaultOptionType[]>([]);
  const [temporaryAbsenteeOptions, setTemporaryAbsentee] = useState<
    DefaultOptionType[]
  >([]);
  const [functionOptions, setFunctionOptions] = useState<DefaultOptionType[]>(
    []
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [visibleDates, setVisibleDates] = useState(false);

  const { hasRole, roles, userData, saveUserData } = useAuth();
  const userGroupRepository = useUserGroups();
  const repositoryUserProfile = useProfile();
  const userRepository = useUsers();
  const commonRepository = useCommon();
  const [form] = Form.useForm<TProfileForm>();
  const phoneWatch = Form.useWatch("telephone", form);
  const emailWatch = Form.useWatch("email", form);
  const pictureWatch = Form.useWatch("picture", form);

  const handleOk = () => form.submit();
  const handleClose = () => setIsModalVisible(false);

  const onFinish = async (values: any) => {
    // console.log(values);
    const data = {
      ...values,
      userFunctionId: values.userFunctionId || "",
      userAreaId: values.userAreaId || "",
      periodAbsenceFrom: values.periodAbsenceFrom
        ? values.periodAbsenceFrom[0]
        : "",
      periodAbsenceUntil: values.periodAbsenceFrom
        ? values.periodAbsenceFrom[1]
        : "",
      substituteApprover: values.substituteApprover,
      temporaryAbsentee: values.temporaryAbsentee ? true : false,
    };

    const _userData = {
      ...userData,
      userName: values.name,
      userPicture: values.picture,
    };

    const response = await repositoryUserProfile.updateProfile(data);
    if (!response) return;

    message.success("Perfil atualizado com sucesso!");
    saveUserData(_userData);
    handleClose();
  };

  useEffect(() => {
    // console.log(userData);
  }, [userData]);

  const getUsers = async (userFunctionId: any) => {
    const params: any = {
      page: null,
      size: 1000,
      // SOLUÇÂO PALIATIVA PARA PROBLEMA DE SUBSTITUIÇÂO SOLICITADA POR BRUNO ROCHA
      // userFunctionId: userFunctionId,
      activityApprover: true,
      temporaryAbsentee: false,
    };

    const r = await userRepository.findByFilterUsers(params);
    if (!r) return;
    const mappedUsers = r.content.filter(
      (user: any) => user.approver && user.id !== form.getFieldValue("id")
    );
    const _mappedUsers = mappedUsers.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    const _temporaryAbsentee = convertToOptions(_mappedUsers);
    setTemporaryAbsentee(_temporaryAbsentee);
  };

  useEffect(() => {
    const getUserProfile = async () => {
      const response: any = await repositoryUserProfile.findUserProfile();
      getUsers(response.userFunction?.id || null);

      if (!response) return;

      // console.log('modal: ', response);

      const userProfile = {
        ...response,
        telephone: response.telephone ? response.telephone : "",
        userGroupId: response.userGroup?.id,
        userGroupName: response.userGroup?.name,
        userAreaId: response.userArea?.id ?? 0,
        userAreaName: response.userArea?.name,
        userFunctionId: response.userFunction?.id ?? 0,
        userFunctionName: response.userFunction?.name,
        picture: response.picture || "",
        approver: response.approver,
        temporaryAbsentee: response.temporaryAbsentee,
        periodAbsenceFrom:
          response.periodAbsenceFrom && response.periodAbsenceUntil
            ? [
                moment(response.periodAbsenceFrom),
                moment(response.periodAbsenceUntil),
              ]
            : "",
        substituteApprover: response.substituteApprover,
      };
      setVisibleDates(response.temporaryAbsentee);
      form.setFieldsValue(userProfile);
    };

    const requestUserGroups = async () => {
      const params = { size: 1000, page: 0, groupStatus: Status.ACTIVE };
      const response = await userGroupRepository.findGroupsByFilter(params);
      if (!response) return;

      const userGroups = convertToOptions(response.content);
      setGroupOptions(userGroups);
    };

    const getUserFunctionsAndArea = async () => {
      const [userArea, userFunction] = await Promise.all([
        commonRepository.findUserAreaByFilter(),
        commonRepository.findUserFunctionByFilter(),
      ]);

      const _userArea = convertToOptions(userArea);
      const _userFunction = convertToOptions(userFunction);

      setAreaOptions(_userArea);
      setFunctionOptions(_userFunction);
    };

    form.resetFields();
    if (isModalVisible) {
      requestUserGroups();
      getUserFunctionsAndArea();
      getUserProfile();
    }
  }, [isModalVisible]);

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      form.setFieldsValue({ picture: "" });
    },
    beforeUpload: async (file) => {
      const isAcceptFileType = acceptedFileTypes.includes(file.type);
      if (!isAcceptFileType) {
        message.error(messageInvalidFileType);
        return isAcceptFileType || Upload.LIST_IGNORE;
      }

      if (file.size > 8388608) {
        message.error(messageExceededFileSize);
        return true;
      }

      setFileList([file]);
      const rawFile = await fileToBase64(file);
      const fileBase64 = `data:${file.type};base64, ${rawFile}`;
      form.setFieldsValue({ picture: fileBase64 });
      return false;
    },
    accept:
      "image/jpeg,image/jpg,image/png,image/bmap,application/pdf,application/xls,application/xlsx,application/csv,application/ods,application/xps,application/txt,application/doc,application/docx,application/odt",
    fileList,
  };

  const isUserAd = useMemo(
    () => emailWatch?.includes("@tv1.com.br"),
    [emailWatch]
  );

  const AvatarPicture = useMemo(() => {
    const formData = form.getFieldsValue();
    const firstLettersName = formData.name?.substring(0, 2);
    if (pictureWatch?.length) {
      return (
        <Avatar size={64} shape="square" src={<Image src={pictureWatch} />} />
      );
    }
    return (
      <Avatar size={64} shape="square">
        {firstLettersName}
      </Avatar>
    );
  }, [pictureWatch]);

  const mask = useMemo(() => {
    const removeMaskChar = phoneWatch?.replace(/_/g, "");
    return removeMaskChar?.length < 15
      ? MaskPhone.TELEPHONE
      : MaskPhone.CELLPHONE;
  }, [phoneWatch]);

  function changeAusense(e: any) {
    const approver = form.getFieldValue("approver");
    const temporaryAbsentee = form.getFieldValue("temporaryAbsentee");
    if (approver && temporaryAbsentee) {
      setVisibleDates(true);
    } else {
      setVisibleDates(false);
    }
  }

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  return (
    <Modal
      title="Editar Perfil"
      visible={isModalVisible}
      okText="Salvar"
      cancelText="Fechar"
      confirmLoading={repositoryUserProfile.loading}
      okButtonProps={{
        disabled: !hasRole(roles?.profile_edit),
      }}
      onOk={handleOk}
      onCancel={handleClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <HiddenField name="picture" />
        <Row>
          <Col xl={12}>
            <TextField
              name="name"
              label="Nome completo"
              disabled
              rules={validations.name}
            />
          </Col>
          <Col xl={12}>
            <MaskedField name="telephone" label="Telefone" mask={mask} />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField name="email" label="Email" disabled />
          </Col>
          <Col xl={12}>
            <TextField name="userGroupName" label="Grupo" disabled />
            {/* <SelectField
              disabled
              label="Grupo"
              name="userGroupId"
              options={groupOptions}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField name="userFunctionName" label="Função" disabled />
            {/* <SelectField
              name="userFunctionId"
              label="Função"
              disabled
              options={functionOptions}
            /> */}
          </Col>
          <Col xl={12}>
            <TextField name="userAreaName" label="Area" disabled />
            {/* <SelectField
              name="userAreaId"
              label="Area"
              disabled
              options={areaOptions}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <CheckField
              name="approver"
              label="Aprovador"
              onChange={changeAusense}
              disabled
            />
          </Col>
          <Col xl={18}>
            <CheckField
              name="temporaryAbsentee"
              label="Ausente temporariamente"
              disabled={!form.getFieldValue("approver")}
              required
              onChange={changeAusense}
            />
          </Col>
        </Row>
        {visibleDates ? (
          <>
            <Row>
              <Col xl={24}>
                <DateField
                  name="periodAbsenceFrom"
                  label="Período de ausência"
                  disabledDate={disabledDate}
                  rangePicker
                  required
                  rules={[{ required: true, message: "Selecione o período" }]}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <SelectField
                  name="substituteApprover"
                  label="Substituto para alçada"
                  required
                  options={temporaryAbsenteeOptions}
                  rules={[
                    { required: true, message: "Selecione um substituto" },
                  ]}
                />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        <Row>
          <Col xs={4} xl={4}>
            {AvatarPicture}
          </Col>
          <Col xs={20} xl={20}>
            <Upload {...uploadProps}>
              <Button disabled={!!fileList.length}>
                Selecione uma imagem para upload
              </Button>
            </Upload>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProfileForm;
