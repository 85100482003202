enum Colors {
  BLACK = '#060606',
  WHITE = '#fff',
  RED = '#e53e3e',
  YELLOW = '#ffb601',
  MEDIUM_BLUE = '#3182ce',
  LIGHT_BLUE = '#d7e0ff',
  BLUE = '#3864f9',
  MEDIUM_RED = '#9f3a38',
  GRAY_50 = '#fdfdfd',
  LIGHT_GRAY = '#f3f3f3',
  LIGHTER_GRAY = '#fbfbfb',
  MEDIUM_GRAY = '#9f9f9f',
  DARK_GRAY = '#808080',
  GREEN = '#019973',
  LIGHT_GREEN = '#ecf7f2',
  SECONDARY_GREEN = '#68927e',
  SECONDARY_BLUE = '#36526a',
  SECONDARY_YELLOW = '#fbb548'
}

enum Borders {
  SOLID_RED = '1px solid #ee2e23',
  SOLID_GREEN = '1px solid #019973',
  SOLID_MEDIUM_GREEN = '2px solid #019973',
  SOLID_LIGHT_GRAY = '1px solid #e7e7e7',
  SOLID_MEDIUM_GRAY = '1px solid #9f9f9f',
  SOLID_LIGHTER_GRAY = '1px solid #f5f5f5',
  SOLID_WHITE = '1px solid #fff',
  RADIUS_6 = '6px',
  RADIUS_4 = '4px'
}

type TTheme = {
  colors: typeof Colors
  breakPoints: typeof BreakPoints
  borders: typeof Borders
}

enum BreakPoints {
  SM = '576px',
  MD = '768px',
  LG = '992px',
  MB = '1024px',
  XL = '1200px',
  HD = '1366px',
  XXL = '1400px',
}

export const theme: TTheme = {
  colors: Colors,
  breakPoints: BreakPoints,
  borders: Borders
}
