import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 12px;
  }

  button {
    padding: 0;
    margin: 0;
  }

  button svg {
    width: 16px;
    height: 16px;
  }
`

export const Content = styled.div`
  div {
    font-size: 12px;
    overflow-x: auto;
    @media(max-width: 1920px) {
      max-width: 690px;
    }
    @media(max-width: 1536px) {
      max-width: 520px;
    }
    @media(max-width: 1366px) {
      max-width: 440px;
    }
    
  }
`