import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Input,
  Table
} from 'antd'
import type { ColumnsType, TableProps } from 'antd/lib/table'
import {
  TProject,
  TProjectParams,
  useProject
} from 'repositories'
import { formatDateToView } from 'utils/helpers'
import { useAuth, usePagination } from 'hooks'
import { Navigate, useNavigate } from 'react-router'
import { SorterResult } from 'antd/lib/table/interface'
import { PageHeader, Section } from 'components'
import { tableLocale } from 'utils/lang'
import { FilterContainer } from './styles'
import AvatarGroup from '../Components/AvatarGroup'

type TProjectData = TProject & {
  key: string
}

const columns: ColumnsType<TProjectData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: 'Id Projeto',
    dataIndex: 'idERP',
    key: 'idERP',
    sorter: true,
    align: 'center'
  },
  {
    title: 'Unidade de Negócio [BU]',
    dataIndex: 'buName',
    key: 'bu',
    sorter: true,
    align: 'center'
  },
  {
    title: 'Cliente',
    dataIndex: 'clientName',
    key: 'clientName',
    sorter: true,
    align: 'center'
  },
  {
    title: 'Nome do projeto',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    align: 'center'
  },
  {
    title: 'Tipo do projeto',
    dataIndex: 'projectTypeName',
    key: 'projectTypeName',
    sorter: true,
    align: 'center'
  },
  {
    title: 'Criado em',
    dataIndex: 'inclusionDate',
    key: 'inclusionDate',
    align: 'center'
  },
  {
    title: 'Fase CRM',
    dataIndex: 'phaseCrm',
    key: 'phaseCRM',
    sorter: true,
    align: 'center'
  },
  {
    title: 'Data da venda',
    dataIndex: 'dateOfSale',
    key: 'dateOfSale',
    align: 'center'
  },
  {
    title: 'Responsáveis',
    dataIndex: 'userInclusion',
    key: 'userInclusion',
    align: 'center',
    render: (_, record) => (
      <AvatarGroup
        users={[
          record.userAccountLeader,
          record.userBuDirector,
          record.userOperationsLeader,
          record.userCeo
        ]}
      />
    )
  },
  {
    title: 'Fase TOTVS',
    dataIndex: 'phaseTotvs',
    key: 'phaseTOTVS',
    sorter: true,
    align: 'center'
  },
]

const ProjectList = () => {
  const [projects, setProjects] = useState<TProjectData[]>([])

  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('')

  const { roles: authRoles, hasRole } = useAuth()
  const repository = useProject()
  const navigate = useNavigate()
  const pagination = usePagination()

  const requestProjects = async () => {
    const currentPage = pagination.data.current ?? 0
    const params: TProjectParams = {
      search: search,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
    }

    const response = await repository.findProjectByFilter(params)
    if (!response) return

    const mappedData: TProjectData[] = response.content.map(project => ({
      ...project,
      key: String(project.id),
      dateOfSale: formatDateToView(project.dateOfSale),
      inclusionDate: formatDateToView(project.inclusionDate),
    }))
    setProjects(mappedData)
    pagination.changeTotalPages(response.totalPages)
  }

  const handleChange: TableProps<TProjectData>['onChange'] = (paginationConfig, filters, sorter) => {
    pagination.onChangeTable(paginationConfig)
    const { field, order } = sorter as SorterResult<TProjectData>
    if (!field) return

    const dir = order === 'ascend' ? 'ASC' : 'DESC'
    setSort(`${field},${dir}`)
  }

  const onSearch = (value: string) => {
    setSearch(value)
    pagination.onChange(1, pagination.data.pageSize ?? 1)
  }

  useEffect(() => {
    if (pagination.data.current) requestProjects()
  }, [pagination.data.current, pagination.data.pageSize, sort, search])

  const onClickGoToView = (id: number) => () => navigate(`/projetos/detalhes/${id}`)

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Button
          onClick={onClickGoToView(record.id)}
          size='small'
          type='text'
        >
          Visualizar
        </Button>
      ),
    }
  ], [])

  if (authRoles && !hasRole(authRoles?.project_view)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <PageHeader
        title='Projetos'
        breadcrumbItems={['Projetos', 'Listar']}
      />
      <Section>
        <FilterContainer>
          <Input.Search
            placeholder='Pesquise pelo nome'
            onSearch={onSearch}
            enterButton
          />
        </FilterContainer>
        <Table
          size='middle'
          locale={tableLocale}
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={projects}
          pagination={pagination.data}
        />
      </Section>
    </>
  )
}

export default ProjectList
