import useRest from "hooks/useRest";
import { useState } from "react";
import {
  TCreateProject,
  TProject,
  TProjectDetail,
  TProjectParams,
  TProjectResponse,
  TProjectRevision,
  TProjectRevisionParams,
  TUpdateProject,
} from "repositories";

type TFindProjectByProductIdAndNameParams = {
  productId: number;
  projectName: string;
};

type TFindProjectByProductIdAndName = Pick<TProject, "id" | "name">;

const useProject = () => {
  const [loading, setLoading] = useState(false);
  const rest = useRest();

  const findProjectByFilter = async (
    params: TProjectParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TProjectResponse;
  };
  const findProjectItemsByFilter = async (
    params: any,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project/items";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TProjectResponse;
  };

  const findProjectRevisionByFilter = async (
    params: TProjectRevisionParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project-revision";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return [];

    return response.result.content;
  };

  const findProjectByNameAndProductId = async (
    params: TFindProjectByProductIdAndNameParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project/find-by-product";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return [] as TFindProjectByProductIdAndName[];

    return response.result as TFindProjectByProductIdAndName[];
  };

  const findProjectById = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/manager/project/${id}`;
    const response = await rest.get(url);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TProjectDetail;
  };

  const createProject = async (data: TCreateProject, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project";
    const response = await rest.post(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return;

    return response.result as TProject;
  };

  const updateProject = async (data: TUpdateProject, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/project";
    const response = await rest.put(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return;

    return response.result as TProject;
  };

  return {
    loading,
    findProjectByFilter,
    findProjectRevisionByFilter,
    findProjectByNameAndProductId,
    findProjectById,
    createProject,
    findProjectItemsByFilter,
    updateProject,
  };
};

export default useProject;
