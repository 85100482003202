import React, { useEffect, useState } from "react";
import {
  Form,
  Radio,
  Divider,
  Table,
  Tag,
  Rate,
  Popover,
  Checkbox,
  Button,
  Modal,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {
  PageHeader,
  Section,
  Row,
  Col,
  TextField,
  SelectField,
  DateField,
  CurrencyField,
  CheckField,
  AreaField,
} from "components";

import {
  SupplierStatus,
  supplierStatus,
  SupplierType,
} from "../../../utils/constants";

import PurchaseAndHiringDetails from './PurchaseAndHiringDetails'
import PurchaseWithApportionmentDetails from './PurchaseWithApportionmentDetails'

import { useParams } from "react-router-dom";

import moment from "moment";

import { useRequest, useProduct, useSuppliers } from "../../../repositories";

import { useNavigate } from "react-router";

import axios from "services/axios";
import { formatCurrency } from "../../../utils/helpers";

const options = [
  { label: "Monday", value: "Monday" },
  { label: "Compras", value: "Compras" },
  { label: "Elaw", value: "Elaw" },
  { label: "Cervello", value: "Cervello" },
  { label: "Power BI", value: "Power BI" },
  { label: "TOTVS", value: "TOTVS" },
  { label: "Pacote Office", value: "Pacote Office" },
];

const requestsColumns: any = [
  {
    title: "Emissão",
    dataIndex: "issueDate",
    render: (_: any, record: any) => {
      return record.issueDate
        ? moment(record.issueDate).format("DD/MM/YYYY")
        : "";
    },
  },
  {
    title: "Vencimento",
    dataIndex: "maturity",
    render: (_: any, record: any) => {
      return record.maturity
        ? moment(record.maturity).format("DD/MM/YYYY")
        : "";
    },
  },
  {
    title: "Produto",
    dataIndex: "productName",
  },
  {
    title: "Prestador",
    dataIndex: "typeProvider",
    render: (_: any, record: any) => {
      return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
    },
  },
  {
    title: "Tipo",
    dataIndex: "typeRequest",
    render: (_: any, record: any) => {
      if (record.typeRequest === "SIMPLE") return "Simples";
      return "Composta";
    },
  },
  {
    title: "Categoria",
    dataIndex: "category",
  },
  {
    title: "Alocação",
    dataIndex: "allocation",
  },
  {
    title: "Local",
    dataIndex: "buName",
  },
  {
    title: "Cliente",
    dataIndex: "client",
  },
  {
    title: "Timing",
    dataIndex: "timing",
    render: (_: any, record: any) => {
      if (record.timing === "PLANEJADA")
        return <Tag color="green">Planejada</Tag>;

      return <Tag color="red">Não planejada</Tag>;
    },
  },
  {
    title: "Fatura",
    dataIndex: "paymentType",
  },
  {
    title: "Valor da requisição",
    dataIndex: "totalCost",
    render: (_: any, record: any) => {
      return formatCurrency(record?.totalCost);
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (_: any, record: any) => {
      switch (record.status) {
        case "NEW":
          return <Tag color="cyan">Novo</Tag>;
        case "IN_QUOTATION":
          return <Tag color="yellow">Em cotação</Tag>;
        case "APPROVED":
          return <Tag color="green">Aprovado</Tag>;
        case "REFUSED":
          return <Tag color="orange">Reprovada</Tag>;
        case "CANCELED":
          return <Tag color="red">Cancelada</Tag>;
        case "ON_APPROVAL":
          return <Tag color="yellow">Em aprovação</Tag>;
        case "PAID_OUT":
          return <Tag color="green">Pago</Tag>;
        default:
          return record.status;
      }
    },
  },
];

function applyPhoneMaskWithNinthDigit(phoneNumber: any) {
  const onlyNumbers = phoneNumber.replace(/\D/g, ""); // Remove caracteres não numéricos

  let formattedNumber;
  if (onlyNumbers.length === 11) {
    formattedNumber = onlyNumbers.replace(
      /(\d{2})(\d{5})(\d{4})/,
      "($1) $2-$3"
    );
  } else if (onlyNumbers.length === 10) {
    formattedNumber = onlyNumbers.replace(
      /(\d{2})(\d{4,5})(\d{4})/,
      "($1) $2-$3"
    );
  } else {
    // Tratar casos com números de telefone inválidos
    return phoneNumber;
  }

  return formattedNumber;
}

const columnsSuppliers: any = [
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "Razão Social",
    dataIndex: "corporateName",
  },
  {
    title: "Nome fantasia ",
    dataIndex: "fantasyName",
  },
  {
    title: "Tipo de Prestador",
    dataIndex: "typeSupplier",
    render: (_: any, record: any) =>
      record.typeSupplier === SupplierType.SUPPLIER
        ? "Fornecedor"
        : "Freelancer",
  },
  {
    title: "Telefone",
    dataIndex: "telephone",
    render: (_: any, record: any) => {
      return applyPhoneMaskWithNinthDigit(record.telephone);
    },
  },
  {
    title: "E-mail",
    dataIndex: "email",
  },
  {
    title: "Status Homologação",
    dataIndex: "status",
    render: (_: any, record: any) => {
      if (record.status === SupplierStatus.APPROVED) {
        return <Tag color="green">Homologado</Tag>;
      }

      if (record.status === SupplierStatus.NOT_APPROVED) {
        return <Tag color="red">Bloqueado</Tag>;
      }

      if (record.status === SupplierStatus.PRE_REGISTRATION) {
        return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
      }

      return <Tag>{supplierStatus[record.status]}</Tag>;
    },
  },
  {
    title: "Preciario",
    dataIndex: "preciary",
    align: "center",
    render: (_: any, record: any) => {
      if (record.preciary) {
        return <Tag>Sim</Tag>;
      } else {
        return <Tag>Não</Tag>;
      }
    },
  },
  {
    title: "Valor negociado",
    dataIndex: "value",
    align: "center",
    render: (_: any, record: any) => (
      <span>{formatCurrency(record.value)}</span>
    ),
  },
  {
    title: "Unidade de medida",
    dataIndex: "unitOfMeasurement",
    align: "center",
  },
  {
    title: "Avaliações",
    dataIndex: "currentAmountEvaluation",
    align: "center",
    render: (_: any, record: any) => (
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <Rate disabled value={record.currentEvaluation} />
        <span>{`(${record.currentAmountEvaluation})`}</span>
      </div>
    ),
  },
];

const RequestDetail = () => {
  const [form] = Form.useForm();
  const [freelancersSuppliersList, setFreelancersSuppliersList] = useState<any>(
    []
  );
  const [technicalScope, setTechnicalScope] = useState<any>("");

  const [productsList, setProductsList] = useState<any>([]);
  const [productsLinesNotEdited, setProductsLinesNotEdited] = useState<any>([]);
  const [filesList, setFilesList] = useState<any>([]);
  const [requestModal, setRequestModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [items, setItems] = useState([]);

  const navigate = useNavigate();

  const [checkedValues, setCheckedValues] = useState([]);

  const repository = useRequest();
  const repositoryProduct = useProduct();
  const repositorySuplier = useSuppliers();

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [requestData, setRequestData] = useState<any>();

  const { id } = useParams();

  const voltarParaPaginaAnterior = () => {
    navigate(-1);
  };

  const openRequests = (requests: any) => {
    setRequestModal(requests);
    setIsModalOpen(true);
  };

  const columnsLines: any = [
    {
      title: "Id Compras",
      dataIndex: "id",
    },
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Faturamento",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo da linha",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
          <Popover content={record.technicalScopeDescription} overlayStyle={{ maxWidth: "800px" }}>
            {record.technicalScope}
          </Popover>
        );
      },
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
      render: (_: any, record: any) => {
        return record.amount;
      },
    },
    {
      title: "Diárias",
      dataIndex: "daily",
      render: (_: any, record: any) => {
        return record.daily;
      },
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.unitCost);
      },
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.totalCost);
      },
    },
    {
      title: "Já tem requisições?",
      dataIndex: "withoutRequest",
      align: "center",
      render: (_: any, record: any) => {
        return record.withoutRequest ? (
          <Tag
            color="green"
            style={{ cursor: "pointer" }}
            onClick={() => openRequests(record.projectRequestItems)}
          >
            Sim
          </Tag>
        ) : (
          <Tag color="default">Não</Tag>
        );
      },
    },
    // {
    //   title: "Alvo de pacote ou rateio?",
    //   dataIndex: "packageOrApportionment",
    //   align: "center",
    //   render: (_: any, record: any) => {
    //     return record.packageOrApportionment ? (
    //       <Tag color="green">Sim</Tag>
    //     ) : (
    //       <Tag color="default">Não</Tag>
    //     );
    //   },
    // },
    {
      // soma da quantidade + valor
      title: "Requisições aprovadas",
      dataIndex: "approvalRequests",
      render: (_: any, record: any) => {
        return formatCurrency(record.approvalRequests);
      },
    },
    {
      title: "Saldo da linha",
      dataIndex: "lineBalance",
      render: (_: any, record: any) => {
        return (
          <span style={{ whiteSpace: "nowrap" }}>
            {formatCurrency(record.lineBalance)}
          </span>
        );
      },
    },
  ];
  const columnsLinesEditable: any = [
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Fat.",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      // oq é ?
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
            <Popover
                content={record.technicalScopeDescription}
                overlayStyle={{ maxWidth: "800px" }}
            >
              {record.technicalScope}
            </Popover>
        );
      },
    },
    {
      title: "Quant",
      dataIndex: "amount",
      render: (_: any, record: any) => {

        return record.amount
      },
    },
    {
      title: "Diárias",
      dataIndex: "daily",
      render: (_: any, record: any) => {
        return record.daily
      },
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.unitCost)
      },
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
    },
  ];

  let initialValues = {
    typeRequest: "",
    category: "Compra & Contratação",
    allocation: "Com projeto",
    requestLocation: "",
    client: "",
    invoiceAgainst: "TV1",
    status: "",
    purchaseType: "Projeto",
    providerType: "",
    senioridade: "",
    timing: 2,
    emission: moment(),
    startDate: moment(),
    endDate: moment(),
    pay: moment(),
    ExpenseOrInvestment: "Despesa",
    productType: "",
    quoteDeadline: "",
    SupplierPricing: "",
    unitMinimunPrice: 0,
    unitMaximunPrice: 0,
    productWithPrice: "",
    cotation: "",
    technicalScope: "",
    objectiveFunction: "",
    justifyIfItIsAnEmergency: "",
    JustifyReasonSingleSupplier: "",
    JustifyUnapprovedSupplier: "",
    JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate: "",
    JustifyTheDueDateBeingShorter: "",
    indicationSingleSupplierJustifiable: "",
    needSupportFromTv1corporateTI: "",
    useTv1Email: "",
    equipmentFreelancers: "",
    equipmentTypeFreelancers: "",
    workplace: "",
    accessInternalSystem: "",
    othersAccessInternalSystem: "",
    otherMarketSoftware: "",
    freelanceDirectManager: "",
    contractStartDate: "",
    contractEndDate: "",
  };

  const loadData = async () => {

    const requestResponse = await repository.findRequestById(id, {});

    setRequestData(requestResponse)
    
    setItems(requestResponse?.items.map((item: any) => {

    }))
    
    const productResponse = await repositoryProduct.findProductById(
      requestResponse.items[0].productId
    );

    const supplierResponse = await repositorySuplier.findSupplierByFilter({
      id: requestResponse.suppliers[0].id,
    });

    const productPriceRangeResponse =
      await repositorySuplier.getProductPriceRange(
        requestResponse.suppliers[0].id
      );

    const paramsProjectItems: any = {
      projectId: requestResponse.items[0].projectRevision.project.id,
      projectRevisionId: requestResponse.items[0].projectRevision.id,
      size: 10000
    };

    const responseProjectItems = await repository.findProjectItems(
      paramsProjectItems
    );
    if (!responseProjectItems) return;

    const valuesProducts: any[] = [];

    for (let i = 0; i < requestResponse.items.length; i++) {
      const item = responseProjectItems.content[0]?.items
          .map((request: any) => ({
            id: String(request.id),
            key: request.id,
            idLinha: request.lineIdErp,
            paymentType: request.billingType === "0" ? "TV1" : "Cliente",
            productId: request.productId,
            productDescription: request.productDescription,
            categoria: request.categories[0].description,
            technicalScope: request?.description?.slice(0, 10) + " ...",
            technicalScopeDescription: request?.description,
            amount: request.amount,
            daily: request.daily,
            unitCost: request.unitCost,
            totalCost: request.totalCost,
            withoutRequest: request.existsRequisitions,
            packageOrApportionment: request.activePackageOrRating,
            approvalRequests: request.valueApprovedRequisitions,
            lineBalance: request.valueApprovedRequisitions ? request.balanceLine : request.totalCost,
            projectRequestItems: request.projectRequestItems,
          }))
          .filter(
              (item: any) =>
                  item.productId === requestResponse.items[i].productId &&
                  String(item.idLinha) === String(requestResponse.items[i].lineIdErp)
          );

      const valuesProduct = {
        ...item[0],
        categoria: productResponse?.categories[0]?.description,
        totalCost: formatCurrency(requestResponse?.items[i].totalCost),
        unitCost: (requestResponse?.items[i].totalCost / (requestResponse?.items[i].amount * requestResponse?.items[i].daily)),
        amount: requestResponse?.items[i].amount,
        daily: requestResponse?.items[i].daily
      };
      const valuesNotEdited = {...item[0]}
      // console.log({valuesNotEdited})
      setProductsLinesNotEdited([valuesNotEdited])
      valuesProducts.push(valuesProduct)
    }

    let unitMinimunPriceClone = "";
    let unitMaximunPriceClone = "";

    switch (supplierResponse?.content[0]?.seniority) {
      case "JUNIOR":
        unitMinimunPriceClone = productResponse?.minimumPriceJunior;
        unitMaximunPriceClone = productResponse?.maximumPriceJunior;
        break;
      case "PLENO":
        unitMinimunPriceClone = productResponse?.minimumPricePleno;
        unitMaximunPriceClone = productResponse?.maximumPricePleno;
        break;
      case "SENIOR":
        unitMinimunPriceClone = productResponse?.minimumPriceSenior;
        unitMaximunPriceClone = productResponse?.maximumPriceSenior;
        break;
    }

    let mappedStatus = "";

    switch (requestResponse?.statusRequest) {
      case "NEW":
        mappedStatus = "Novo";
        break;
      case "IN_QUOTATION":
        mappedStatus = "Em cotação";
        break;
      case "APPROVED":
        mappedStatus = "Aprovado";
        break;
      case "REFUSED":
        mappedStatus = "Reprovada";
        break;
      case "CANCELED":
        mappedStatus = "Cancelada";
        break;
      case "ON_APPROVAL":
        mappedStatus = "Em aprovação";
        break;
      case "PAID_OUT":
        mappedStatus = "Pago";
        break;
      default:
        return requestResponse?.statusRequest;
    }

    const valuesRequest = {
      ...requestResponse,
      needSupportFromTv1corporateTI:
        requestResponse.projectRequestFreelancers
          ?.needSupportFromTv1corporateTI === true
          ? "Sim"
          : "Não",
      useTv1Email:
        requestResponse.projectRequestFreelancers?.useTv1Email === true
          ? "Sim"
          : "Não",
      equipmentFreelancers:
        requestResponse.projectRequestFreelancers?.equipmentFreelancers,
      equipmentTypeFreelancers:
        requestResponse.projectRequestFreelancers?.equipmentTypeFreelancers,
      workplace: requestResponse.projectRequestFreelancers?.workplace,
      accessInternalSystem:
        requestResponse.projectRequestFreelancers?.accessInternalSystem,
      othersAccessInternalSystem:
        requestResponse.projectRequestFreelancers?.othersAccessInternalSystem,
      otherMarketSoftware:
        requestResponse.projectRequestFreelancers?.otherMarketSoftware,
      freelanceDirectManager:
        requestResponse.projectRequestFreelancers?.freelanceDirectManager,
      contractStartDate: moment(
        requestResponse.projectRequestFreelancers?.contractStartDate
      ),
      contractEndDate: moment(
        requestResponse.projectRequestFreelancers?.contractEndDate
      ),
      typeRequest:
        requestResponse?.typeRequest === "SIMPLE" ? "Simples" : "Composta",
      client: requestResponse?.items[0]?.projectRevision?.project?.clientName,
      requestLocation:
        requestResponse?.items[0]?.projectRevision?.project?.buName,
      status: mappedStatus,
      timing: requestResponse?.approvalHierarchyTiming === "PLANEJADA" ? "PLANEJADA" : "NÂO PLANEJADA",
      emission: moment(requestResponse?.issueDate).format("DD/MM/YYYY"),
      startDate: moment(requestResponse?.serviceStartDate).format("DD/MM/YYYY"),
      endDate: moment(requestResponse?.serviceEndDate).format("DD/MM/YYYY"),
      maturity: moment(requestResponse?.maturity).format("DD/MM/YYYY"),
      providerType:
        requestResponse?.typeProvider === SupplierType.SUPPLIER
          ? "Fornecedor"
          : "Freelancer",
      technicalScope: requestResponse?.items[0]?.technicalScope,
      senioridade: supplierResponse?.content[0]?.seniority,
      unitMinimunPrice: unitMinimunPriceClone,
      unitMaximunPrice: unitMaximunPriceClone,
      ...requestResponse?.projectRequestAdditionalQuestions,
    };

    valuesRequest.productType = productResponse.productType.description;

    const filtered = productPriceRangeResponse.filter(
      (product: any) => product.productId === valuesProducts[0]?.productId
    );
    setTechnicalScope(requestResponse?.items[0]?.technicalScope)
    const valuesSuppliers = {
      ...supplierResponse?.content[0],
      preciary: filtered[0]?.preciary,
      value: filtered[0]?.value ? filtered[0]?.value : "",
      unitOfMeasurement: filtered[0]?.unitOfMeasurement
        ? filtered[0]?.unitOfMeasurement
        : "",
    };

    setFreelancersSuppliersList([valuesSuppliers]);
    setProductsList(valuesProducts);
    setFilesList(requestResponse.files);
    setCheckedValues(
      requestResponse.projectRequestFreelancers?.accessInternalSystem
    );

    form.setFieldsValue(valuesRequest);
  };

  const downloadArchive = async (fileName: any) => {
    try {
      setDownloadLoading(true);
      const response = await axios.get(
        "/v1/manager/project-request/download/" + fileName,
        {
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadLoading(false);
    }
  };

  const onClickGoBack = () => {
    navigate("/");
  };

  useEffect(() => {
    loadData();
  }, []);

  const updatedColumns: any = [
    {
      title: "Id projeto",
      dataIndex: "idLinha",
    },
    {
      title: "Nome do projeto",
      dataIndex: "projectName",
    },
    {
      title: "Percentual do rateio (%)",
      dataIndex: "percentage",
    },
    {
      title: "1° Parcela",
    },
    {
      title: "2° Parcela",
    },
    {
      title: "Total",
    }
  ];

  return (

    requestData?.typeRequest ?
      requestData?.typeRequest === 'SIMPLE' ?
      <PurchaseAndHiringDetails requestData={requestData} />
      :
      <PurchaseWithApportionmentDetails />
    :
    <></>
  );
};

export default RequestDetail;
