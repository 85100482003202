import styled from 'styled-components'
import {Tooltip} from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 5px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 14px;
  }

  button {
    padding: 0;
    margin: 0;
  }

  button svg {
    width: 16px;
    height: 16px;
  }
`

export const Content = styled.div`
  div {
    font-size: 14px;
    align-items: baseline;
    gap: 1vw;
  }
  span {
    font-size: 14px;
  }
`
